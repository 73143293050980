// Actions associated with order
import { Dispatch } from "redux";
import { post } from "src/api/server";
import { OrderFragment } from "src/state/order/types";

export enum ORDER_ACTION {
  GET_ALL_ORDERS_FOR_CUSTOMER_ACTION = "GET_ALL_ORDERS_FOR_CUSTOMER_ACTION",
  GET_ACTIVE_ORDERS_FOR_CUSTOMER_ACTION = "GET_ACTIVE_ORDERS_FOR_CUSTOMER_ACTION",
  GET_SPECIFIC_ORDER_ACTION = "GET_SPECIFIC_ORDER_ACTION",
  GET_RECENTLY_CANCELLED_ORDER_FOR_CUSTOMER_ACTION = "GET_RECENTLY_CANCELLED_ORDER_FOR_CUSTOMER_ACTION",
  GET_RECENTLY_COMPLETED_ORDER_FOR_CUSTOMER_ACTION = "GET_RECENTLY_COMPLETED_ORDER_FOR_CUSTOMER_ACTION",
  CLEAR_ORDERS_FROM_STATE_ACTION = "CLEAR_ORDERS_FROM_STATE_ACTION",
}

export interface GetAllOrdersForCustomerAction {
  type: ORDER_ACTION.GET_ALL_ORDERS_FOR_CUSTOMER_ACTION;
  restaurantId: string;
  response: OrderFragment[];
}

export const getAllOrdersForCustomerAction = (
  restaurantId: string,
  customerId: string,
) => {
  return async (dispatch: Dispatch<GetAllOrdersForCustomerAction>) => {
    const response = (await post({
      endpoint: "/order/list",
      params: {
        customerId,
      },
    })) as OrderFragment[];

    const action = {
      type: ORDER_ACTION.GET_ALL_ORDERS_FOR_CUSTOMER_ACTION,
      restaurantId,
      response,
    } as GetAllOrdersForCustomerAction;

    dispatch(action);

    return action;
  };
};

export interface GetSpecificOrderAction {
  type: ORDER_ACTION.GET_SPECIFIC_ORDER_ACTION;
  restaurantId: string;
  response: OrderFragment;
}

export const getSpecificOrderAction = (
  restaurantId: string,
  orderId: string,
) => {
  return async (dispatch: Dispatch<GetSpecificOrderAction>) => {
    const response = (await post({
      endpoint: "/order/get",
      params: {
        orderId,
      },
    })) as OrderFragment;

    const action = {
      type: ORDER_ACTION.GET_SPECIFIC_ORDER_ACTION,
      restaurantId,
      response,
    } as GetSpecificOrderAction;

    dispatch(action);

    return action;
  };
};

export interface GetActiveOrdersForCustomerAction {
  type: ORDER_ACTION.GET_ACTIVE_ORDERS_FOR_CUSTOMER_ACTION;
  customerId: string;
  restaurantId: string;
  response: OrderFragment[];
}

export const getActiveOrdersForCustomerAction = (
  customerId: string,
  restaurantId: string,
) => {
  return async (dispatch: Dispatch<GetActiveOrdersForCustomerAction>) => {
    const response = (await post({
      endpoint: "/order/list",
      params: {
        customerId,
        getOnlyActiveOrders: true,
      },
    })) as OrderFragment[];

    const action = {
      type: ORDER_ACTION.GET_ACTIVE_ORDERS_FOR_CUSTOMER_ACTION,
      customerId,
      restaurantId,
      response,
    } as GetActiveOrdersForCustomerAction;

    dispatch(action);

    return action;
  };
};

export interface GetRecentlyCancelledOrderForCustomerAction {
  type: ORDER_ACTION.GET_RECENTLY_CANCELLED_ORDER_FOR_CUSTOMER_ACTION;
  response: {
    order: OrderFragment | null;
  };
  restaurantId: string;
}

export const getRecentlyCancelledOrderForCustomerAction = (
  customerId: string,
  restaurantId: string,
) => {
  return async (
    dispatch: Dispatch<GetRecentlyCancelledOrderForCustomerAction>,
  ) => {
    const response = (await post({
      endpoint: "/order/get_recent_cancelled_order",
      params: {
        customerId,
      },
    })) as {
      order: OrderFragment | null;
    };

    const action = {
      type: ORDER_ACTION.GET_RECENTLY_CANCELLED_ORDER_FOR_CUSTOMER_ACTION,
      response,
      restaurantId,
    } as GetRecentlyCancelledOrderForCustomerAction;

    dispatch(action);

    return action;
  };
};

export interface GetRecentlyCompletedOrderForCustomerAction {
  type: ORDER_ACTION.GET_RECENTLY_COMPLETED_ORDER_FOR_CUSTOMER_ACTION;
  response: {
    order: OrderFragment | null;
  };
}

export const getRecentlyCompletedOrderForCustomerAction = (
  customerId: string,
) => {
  return async (
    dispatch: Dispatch<GetRecentlyCompletedOrderForCustomerAction>,
  ) => {
    const response = (await post({
      endpoint: "/order/get_recent_completed_order",
      params: {
        customerId,
      },
    })) as {
      order: OrderFragment | null;
    };

    const action = {
      type: ORDER_ACTION.GET_RECENTLY_COMPLETED_ORDER_FOR_CUSTOMER_ACTION,
      response,
    } as GetRecentlyCompletedOrderForCustomerAction;

    dispatch(action);

    return action;
  };
};

export interface ClearOrdersFromStateAction {
  type: ORDER_ACTION.CLEAR_ORDERS_FROM_STATE_ACTION;
}

export const clearOrdersFromStateAction = () => {
  return async (dispatch: Dispatch<ClearOrdersFromStateAction>) => {
    const action = {
      type: ORDER_ACTION.CLEAR_ORDERS_FROM_STATE_ACTION,
    } as ClearOrdersFromStateAction;

    dispatch(action);

    return action;
  };
};
