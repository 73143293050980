import classNames from "classnames";
import styles from "src/pages/Cart/OrderDetails/styles.module.scss";
import { ORDER_TYPE } from "src/state/order/types";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "src/state/state";
import { isRestaurantCurrentlyOpen } from "src/common/date";
import { OrderDetailsOptions } from "src/pages/Cart/OrderDetails/OrderDetailsOptions/OrderDetailsOptions";
import { CartDealFragment, CartItemFragment } from "src/state/cart/types";
import { LOYALTY_TYPE } from "src/common/types/Loyalty";
import { PointsSelector } from "src/pages/Cart/OrderDetails/PointsSelector/PointsSelector";
import { Button, TextArea } from "src/components";
import { useNavigate } from "react-router-dom";
import { getCartPath, getCheckoutPath, getSignUpPath } from "src/Router/routes";
import ReactLoading from "react-loading";
import { createCheckoutStateAction } from "src/state/checkoutSession/actions";
import {
  calculateDeliveryQuote,
  calculateSalesTaxForSubtotal,
  roundToNearestCent,
} from "src/common/price";
import { captureManualSentryException } from "src/common/sentry";
import {
  logCheckoutFromCartAddressFailureToAnalytics,
  logCheckoutFromCartClickedToAnalytics,
  logPointsSelectedInCartToAnalytics,
} from "src/common/analytics";
import { isCartItemADeal } from "src/state/order/utils";
import { OrderDetailsCartItem } from "src/pages/Cart/OrderDetails/OrderDetailsCartItem/OrderDetailsCartItem";
import { getAllItemsObjectFromState } from "src/state/item/utils";
import { TipAmountType } from "src/common/types/Order";
import { DeliveryQuote } from "src/common/types/DeliveryQuote";
import { TipPicker } from "src/pages/Cart/OrderDetails/TipPicker/TipPicker";
import { CreateDeliveryParams } from "src/common/order";
import { DEAL_VALUE_TYPE } from "src/state/deal/types";
import { LocationFragment } from "src/common/types/Location";
import { useDesign } from "src/common/getDesign";

interface OrderDetailsProps {
  className?: string;
}

export const OrderDetails = ({ className }: OrderDetailsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const design = useDesign();
  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const cart = useSelector((state: State) => state.cart);
  const items = useSelector((state: State) => state.items);
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const restaurantWideDiscount = useSelector(
    (state: State) =>
      restaurant && state.restaurantWideDiscounts[restaurant.id],
  );
  const deals = useSelector(
    (state: State) => restaurant && state.deals[restaurant.id],
  );
  const selectedOrderType = useSelector(
    (state: State) => state.orderType.selectedOrderType,
  );
  const selectedLocation = useSelector(
    (state: State) => state.location.selectedLocation as LocationFragment,
  );

  const allItemsObject = useMemo(
    () => getAllItemsObjectFromState(items),
    [items],
  );

  const cartArray = useMemo(() => {
    return Object.values(cart);
  }, [cart]);

  const cartSubTotal = useMemo(
    () => cartArray && cartArray.reduce((a, v) => a + v.totalPrice, 0),
    [cartArray],
  );

  const shouldShowPointsSelected = useMemo(() => {
    if (customer && restaurant) {
      if (
        restaurant.loyalty.type === LOYALTY_TYPE.POINTS_SYSTEM &&
        customer.points >=
          (restaurant.loyalty.pointsSystemAmountSpend as number) * 100
      ) {
        return true;
      }
    }

    return false;
  }, [customer, restaurant]);

  const pointsToUseInDollars = useMemo(() => {
    if (customer && restaurant) {
      const minimumSpendRequiredInDollars = restaurant.loyalty
        .pointsSystemAmountSpend as number;
      const pointsValueAtMinimum = restaurant.loyalty
        .pointsSystemAmountEarn as number;

      const minimumSpendRequiredInPoints = minimumSpendRequiredInDollars * 100;

      const pointsValueInDollars =
        Math.floor(customer.points / minimumSpendRequiredInPoints) *
        pointsValueAtMinimum;

      if (pointsValueInDollars > cartSubTotal) {
        return Math.ceil(cartSubTotal);
      }

      return pointsValueInDollars;
    }

    return 0;
  }, [customer, cartSubTotal]);

  const [time, setTime] = useState<Date | undefined>(undefined);
  const [selectedAddress, setSelectedAddress] = useState<string | undefined>();
  const [deliveryInstructions, setDeliveryInstructions] = useState<string>("");
  const [contactlessDelivery, setContactlessDelivery] = useState<boolean>(true);
  const [isPointsSelected, setIsPointsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [additionalNotes, setAdditionalNotes] = useState<string>("");
  const [restaurantTipAmountType, setRestaurantTipAmountType] = useState(
    TipAmountType.FIFTEEN_PERCENT,
  );
  const [restaurantCustomTipAmountString, setRestaurantCustomTipAmountString] =
    useState("");
  const [driverTipAmountType, setDriverTipAmountType] = useState(
    TipAmountType.CUSTOM,
  );
  const [driverCustomTipAmountString, setDriverCustomTipAmountString] =
    useState("0");
  const [deliveryQuote, setDeliveryQuote] = useState<
    DeliveryQuote | undefined
  >();
  const [restaurantTipError, setRestaurantTipError] = useState<
    string | undefined
  >();
  const [driverTipError, setDriverTipError] = useState<string | undefined>();
  const [addressError, setAddressError] = useState<string | undefined>();

  const valueForRestaurantWideDiscount = useMemo(() => {
    if (restaurantWideDiscount) {
      let subtotal = cartSubTotal;

      if (isPointsSelected) {
        subtotal -= pointsToUseInDollars;
      }

      if (restaurantWideDiscount.dealValueType === DEAL_VALUE_TYPE.DOLLAR) {
        return restaurantWideDiscount.dealValue;
      } else {
        return subtotal * (restaurantWideDiscount.dealValue / 100);
      }
    }
  }, [
    restaurantWideDiscount,
    cartSubTotal,
    pointsToUseInDollars,
    isPointsSelected,
  ]);

  const cartSubtotalWithRewardsApplied = useMemo(() => {
    let finalAmount = cartSubTotal;

    if (isPointsSelected) {
      finalAmount -= pointsToUseInDollars;
    }

    if (valueForRestaurantWideDiscount) {
      finalAmount -= valueForRestaurantWideDiscount;
    }

    return finalAmount;
  }, [
    cartSubTotal,
    isPointsSelected,
    pointsToUseInDollars,
    valueForRestaurantWideDiscount,
  ]);

  const isRestaurantTipValid = useCallback((): boolean => {
    const isRestaurantTipCustom =
      restaurantTipAmountType === TipAmountType.CUSTOM;

    if (
      isRestaurantTipCustom &&
      (isNaN(parseFloat(restaurantCustomTipAmountString)) ||
        parseFloat(restaurantCustomTipAmountString) < 0)
    ) {
      setRestaurantTipError("Please enter a valid tip amount.");
      return false;
    }

    return true;
  }, [restaurantCustomTipAmountString, restaurantTipAmountType]);

  const isDriverTipValid = useCallback((): boolean => {
    const isDriverTipCustom = driverTipAmountType === TipAmountType.CUSTOM;

    if (
      isDriverTipCustom &&
      (isNaN(parseFloat(driverCustomTipAmountString)) ||
        parseFloat(driverCustomTipAmountString) < 0)
    ) {
      setDriverTipError("Please enter a valid tip amount.");
      return false;
    }

    return true;
  }, [driverCustomTipAmountString, driverTipAmountType]);

  const restaurantTipAmount = useMemo(() => {
    if (restaurant && !restaurant.restaurantSettings.isRestaurantTipsEnabled) {
      return 0;
    }

    if (restaurantTipAmountType === TipAmountType.CUSTOM) {
      if (`${restaurantCustomTipAmountString}`.trim() === "") {
        setRestaurantTipError(undefined);
        return 0;
      } else if (isRestaurantTipValid()) {
        setRestaurantTipError(undefined);
        return roundToNearestCent(parseFloat(restaurantCustomTipAmountString));
      }
      return 0;
    }
    return roundToNearestCent(
      cartSubtotalWithRewardsApplied * restaurantTipAmountType,
    );
  }, [
    restaurant,
    restaurantTipAmountType,
    cartSubtotalWithRewardsApplied,
    restaurantCustomTipAmountString,
  ]);

  const driverTipAmount = useMemo(() => {
    if (driverTipAmountType === TipAmountType.CUSTOM) {
      if (`${driverCustomTipAmountString}`.trim() === "") {
        setDriverTipError(undefined);
        return 0;
      } else if (isDriverTipValid()) {
        setDriverTipError(undefined);
        return roundToNearestCent(parseFloat(driverCustomTipAmountString));
      }
      return 0;
    }
    return roundToNearestCent(
      cartSubtotalWithRewardsApplied * driverTipAmountType,
    );
  }, [
    driverTipAmountType,
    cartSubtotalWithRewardsApplied,
    driverCustomTipAmountString,
  ]);

  const cartTotal = useMemo(() => {
    return (
      cartSubtotalWithRewardsApplied + restaurantTipAmount + driverTipAmount
    );
  }, [
    cartSubTotal,
    restaurantTipAmount,
    driverTipAmount,
    cartSubtotalWithRewardsApplied,
  ]);

  const pointsTheCustomerWouldEarn = useMemo(() => {
    if (restaurant) {
      const { loyalty } = restaurant;

      if (loyalty.type === LOYALTY_TYPE.NONE) {
        return 0;
      }

      return cartSubtotalWithRewardsApplied * 100;
    }

    return 0;
  }, [cartSubtotalWithRewardsApplied, restaurant]);

  const pointsTheCustomerWouldEarnInDollars = useMemo(() => {
    if (restaurant) {
      const { loyalty } = restaurant;

      if (loyalty.type === LOYALTY_TYPE.NONE) {
        return 0;
      }

      if (loyalty.pointsSystemAmountEarn && loyalty.pointsSystemAmountSpend) {
        return Math.floor(
          (loyalty.pointsSystemAmountEarn / loyalty.pointsSystemAmountSpend) *
            cartSubtotalWithRewardsApplied,
        );
      }
    }

    return 0;
  }, [cartSubtotalWithRewardsApplied, restaurant]);

  const areCheckoutInputsValid = useCallback((): boolean => {
    if (selectedOrderType === ORDER_TYPE.DELIVERY) {
      if (!selectedAddress || !deliveryQuote) {
        setAddressError("Please enter a delivery address.");
        logCheckoutFromCartAddressFailureToAnalytics(
          customer?.id,
          "Please enter a delivery address.",
          {
            selectedAddress,
            deliveryQuote,
          },
        );
        return false;
      }
    }

    return true;
  }, [selectedOrderType, selectedAddress, deliveryQuote, customer]);

  const handleCheckout = useCallback(async () => {
    setIsLoading(true);
    setAddressError(undefined);

    if (restaurant && areCheckoutInputsValid()) {
      const salesTax = await calculateSalesTaxForSubtotal(
        cartSubtotalWithRewardsApplied,
        selectedLocation.id,
      );

      let createDeliveryParams: CreateDeliveryParams | undefined = undefined;

      if (selectedOrderType === ORDER_TYPE.DELIVERY) {
        const upToDateDeliveryQuote = await calculateDeliveryQuote(
          restaurant.id,
          selectedAddress as string,
          cartSubtotalWithRewardsApplied,
          selectedLocation.id,
          time ? time.toISOString() : undefined,
        );

        createDeliveryParams = {
          deliveryQuote: upToDateDeliveryQuote as DeliveryQuote,
          customerAddress: selectedAddress as string,
          deliveryInstructions,
          contactlessDelivery,
          driverTipInDollars: driverTipAmount,
        };
      }

      await createCheckoutStateAction(
        customer?.id,
        restaurant.id,
        cartSubtotalWithRewardsApplied,
        salesTax,
        isPointsSelected,
        cart,
        selectedOrderType,
        time,
        additionalNotes,
        restaurantTipAmount,
        createDeliveryParams,
        selectedLocation.id,
      )(dispatch);
      logCheckoutFromCartClickedToAnalytics(
        customer?.id,
        cartArray.length,
        cartTotal,
        selectedOrderType,
      );
      navigate(getCheckoutPath());
    }

    setIsLoading(false);
  }, [
    selectedLocation,
    customer,
    restaurant,
    cart,
    cartArray,
    cartTotal,
    isPointsSelected,
    additionalNotes,
    selectedOrderType,
    time,
    restaurantTipAmount,
    selectedAddress,
    deliveryQuote,
    deliveryInstructions,
    contactlessDelivery,
    driverTipAmount,
    cartSubtotalWithRewardsApplied,
    areCheckoutInputsValid,
    dispatch,
  ]);

  if (!items || !restaurant || !deals) {
    captureManualSentryException(
      new Error("items or restaurant or deals is undefined in OrderDetails"),
    );
    return <div />;
  }

  return (
    <div
      className={classNames(styles.OrderDetails, className)}
      data-testid="order-details"
    >
      <OrderDetailsOptions
        time={time}
        setTime={setTime}
        selectedAddress={selectedAddress}
        setSelectedAddress={(newSelectedAddress) => {
          setSelectedAddress(newSelectedAddress);
          setAddressError(undefined);
        }}
        deliveryInstructions={deliveryInstructions}
        setDeliveryInstructions={setDeliveryInstructions}
        contactlessDelivery={contactlessDelivery}
        setContactlessDelivery={setContactlessDelivery}
        setDeliveryQuote={setDeliveryQuote}
        subtotal={cartSubtotalWithRewardsApplied}
      />
      <div className={styles.cartItemsContainer}>
        {cartArray.map((cartItemOrDeal) => {
          if (isCartItemADeal(cartItemOrDeal)) {
            const cartDeal = cartItemOrDeal as CartDealFragment;

            return (
              <OrderDetailsCartItem
                key={cartDeal.id}
                name={deals[cartDeal.dealId].name}
                totalPrice={cartDeal.totalPrice}
                testId={`cart-item-summary-${cartDeal.dealId}`}
              />
            );
          } else {
            const cartItem = cartItemOrDeal as CartItemFragment;

            return (
              <OrderDetailsCartItem
                key={cartItem.itemId}
                name={allItemsObject[cartItem.itemId].name}
                totalPrice={cartItem.totalPrice}
                testId={`cart-item-summary-${cartItem.itemId}`}
              />
            );
          }
        })}
      </div>
      <div
        className={classNames(styles.additionalNotesContainer, {
          [styles.borderBottom]: shouldShowPointsSelected && customer,
        })}
      >
        <TextArea
          testId="additional-notes-input"
          label="Additional Notes"
          placeholder="Enter any notes about your order here..."
          onChangeText={setAdditionalNotes}
          value={additionalNotes}
          maxLength={75}
        />
      </div>
      {shouldShowPointsSelected && customer && (
        <PointsSelector
          className={styles.pointsSelector}
          points={customer.points}
          pointsToDollars={pointsToUseInDollars}
          isSelected={isPointsSelected}
          onClick={() => {
            const newIsPointsSelected = !isPointsSelected;

            if (newIsPointsSelected) {
              logPointsSelectedInCartToAnalytics(
                customer.id,
                customer.points,
                cartSubTotal,
              );
            }

            setIsPointsSelected(newIsPointsSelected);
          }}
          disabled={isLoading}
        />
      )}
      <div className={styles.priceContainer}>
        <div className={styles.priceRow}>
          <h4 className={styles.priceRowText}>Subtotal</h4>
          <h4 className={styles.priceRowText} data-testid="cart-subtotal">
            ${cartSubTotal.toFixed(2)}
          </h4>
        </div>
        {isPointsSelected && (
          <div className={styles.priceRow}>
            <h4 className={styles.priceRowText}>Points Used</h4>
            <h4
              data-testid="points-used"
              className={styles.priceRowText}
            >{`($${pointsToUseInDollars.toFixed(2)})`}</h4>
          </div>
        )}
        {valueForRestaurantWideDiscount && (
          <div className={styles.priceRow}>
            <h4 className={styles.priceRowText}>Discount</h4>
            <h4
              data-testid="restaurant-wide-discount"
              className={styles.priceRowText}
            >{`-$${valueForRestaurantWideDiscount.toFixed(2)}`}</h4>
          </div>
        )}
        {restaurant.restaurantSettings.isRestaurantTipsEnabled && (
          <div data-testid="restaurant-tip">
            <div className={styles.priceRow}>
              <h4 className={styles.tipRowText}>
                {selectedOrderType === ORDER_TYPE.DELIVERY
                  ? "Restaurant Tip"
                  : "Tip"}
              </h4>
              <h4 className={styles.tipRowText} data-testid="tip-amount">
                ${restaurantTipAmount.toFixed(2)}
              </h4>
            </div>
            <TipPicker
              tipAmountType={restaurantTipAmountType}
              setTipAmountType={setRestaurantTipAmountType}
              customTipAmountString={restaurantCustomTipAmountString}
              setCustomTipAmountString={setRestaurantCustomTipAmountString}
              error={restaurantTipError}
            />
          </div>
        )}
        {selectedOrderType === ORDER_TYPE.DELIVERY && (
          <div data-testid="driver-tip">
            <div className={styles.priceRow}>
              <h4 className={styles.tipRowText}>Driver Tip</h4>
              <h4 className={styles.tipRowText} data-testid="tip-amount">
                ${driverTipAmount.toFixed(2)}
              </h4>
            </div>
            <TipPicker
              tipAmountType={driverTipAmountType}
              setTipAmountType={setDriverTipAmountType}
              customTipAmountString={driverCustomTipAmountString}
              setCustomTipAmountString={setDriverCustomTipAmountString}
              error={driverTipError}
            />
          </div>
        )}
        <div className={styles.priceRow}>
          <h4 className={styles.orderTotalText}>Order Total</h4>
          <h4 className={styles.orderTotalText} data-testid="cart-total">
            ${cartTotal.toFixed(2)}
          </h4>
        </div>
      </div>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <ReactLoading
            type="spin"
            color={design.buttonColor}
            height={40}
            width={40}
          />
        </div>
      ) : customer ? (
        <Button
          testId="checkout-button"
          className={styles.checkoutButton}
          disabled={
            !isRestaurantCurrentlyOpen(selectedLocation.hoursOfOperation)
          }
          onClick={handleCheckout}
        >
          <h3 className={styles.buttonText}>Checkout</h3>
        </Button>
      ) : (
        <>
          <Button
            testId="sign-up-to-earn-rewards-button"
            className={classNames(
              styles.checkoutButton,
              styles.loginToEarnRewardsButton,
            )}
            disabled={
              !isRestaurantCurrentlyOpen(selectedLocation.hoursOfOperation)
            }
            onClick={() => {
              navigate(getSignUpPath(getCartPath()));
            }}
          >
            <h3 className={styles.buttonText}>
              {restaurant.loyalty.type !== LOYALTY_TYPE.NONE
                ? "Sign Up to Earn Rewards"
                : "Sign Up for a better experience"}
            </h3>
            {pointsTheCustomerWouldEarn > 0 &&
              restaurant.loyalty.type !== LOYALTY_TYPE.NONE && (
                <h4
                  className={styles.youWouldEarnText}
                  data-testid="number-of-points-potentially-earned"
                >
                  {pointsTheCustomerWouldEarnInDollars >= 1
                    ? `Join now and save $${pointsTheCustomerWouldEarnInDollars.toFixed(2)} on your next order!`
                    : `You would earn ${pointsTheCustomerWouldEarn.toLocaleString()} points from this order`}
                </h4>
              )}
          </Button>
          <Button
            testId="checkout-as-guest-button"
            className={styles.checkoutButton}
            secondary={true}
            disabled={
              !isRestaurantCurrentlyOpen(selectedLocation.hoursOfOperation)
            }
            onClick={handleCheckout}
          >
            <h3 className={styles.checkoutAsGuestText}>Checkout as Guest</h3>
          </Button>
        </>
      )}
      {addressError && (
        <h4 className={styles.errorText} data-testid="address-error">
          {addressError}
        </h4>
      )}
    </div>
  );
};
