export const stripeErrors = [
  "Your card has been declined.",
  "Please fill in your card details.",
  "Your card number is incomplete.",
  "Your card's expiration date is incomplete.",
  "Your card's security code is incomplete.",
  "Your postal code is incomplete.",
  "Your card could not be authorized using the postal code provided. Please update the postal code, or contact your card issuer for further details.",
  "We are unable to authenticate your payment method. Please choose a different payment method and try again.",
  "Your card's security code is invalid.",
  "Your card's security code is incorrect.",
  "Your card does not support this type of purchase.",
  "A processing error occurred.",
  "Your card has insufficient funds.",
  "Your ZIP is invalid.",
];
