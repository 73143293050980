import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  getCartPath,
  getMenuPath,
  getSignInPath,
  getTermsPath,
} from "src/Router/routes";
import { isValidEmailAddress, isValidPhoneNumber } from "src/common/validation";
import { TextInput, Button, Checkbox, Image } from "src/components";
import styles from "src/pages/SignUp/styles.module.scss";
import { State } from "src/state/state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";
import { createNewUserInAWSAction } from "src/state/auth/actions";
import { createCustomerInDatabaseAction } from "src/state/customer/actions";
import { captureManualSentryException } from "src/common/sentry";
import {
  logCreateAccountFailureToAnalytics,
  logCreateAccountInitiatedToAnalytics,
  logCreateAccountSuccessToAnalytics,
} from "src/common/analytics";
import { useScrollToTop } from "src/common/useScrollToTop";
import { useDesign } from "src/common/getDesign";
interface SignUpFieldsErrors {
  firstName: string | undefined;
  lastName: string | undefined;
  emailAddress: string | undefined;
  phoneNumber: string | undefined;
  password: string | undefined;
  confirmPassword: string | undefined;
}

export const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const design = useDesign();
  useScrollToTop();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect");

  const isRedirectToCart = useMemo(() => {
    return redirectUrl ? redirectUrl.includes(getCartPath()) : false;
  }, [redirectUrl]);

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [errorMessages, setErrorMessages] = useState<SignUpFieldsErrors>({
    firstName: undefined,
    lastName: undefined,
    emailAddress: undefined,
    phoneNumber: undefined,
    password: undefined,
    confirmPassword: undefined,
  });
  const [errorBox, setErrorBox] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const areFieldsValid = useCallback(() => {
    const newErrorMessages = {
      ...errorMessages,
    };

    if (firstName.trim() === "") {
      newErrorMessages.firstName = "Please enter your first name.";
      logCreateAccountFailureToAnalytics("FIELDS_MISSING");
    }

    if (lastName.trim() === "") {
      newErrorMessages.lastName = "Please enter your last name.";
      logCreateAccountFailureToAnalytics("FIELDS_MISSING");
    }

    if (emailAddress.trim() === "") {
      newErrorMessages.emailAddress = "Please enter an email address.";
      logCreateAccountFailureToAnalytics("FIELDS_MISSING");
    }

    if (
      emailAddress.trim() !== "" &&
      !isValidEmailAddress(emailAddress.trim())
    ) {
      newErrorMessages.emailAddress = "Please enter a valid email address.";
      logCreateAccountFailureToAnalytics("INVALID_EMAIL");
    }

    if (phoneNumber.trim() === "") {
      newErrorMessages.phoneNumber = "Please enter a phone number.";
      logCreateAccountFailureToAnalytics("FIELDS_MISSING");
    }

    if (phoneNumber.trim() !== "" && !isValidPhoneNumber(phoneNumber.trim())) {
      newErrorMessages.phoneNumber = "Please enter a valid phone number.";
      logCreateAccountFailureToAnalytics("INVALID_PHONE_NUMBER");
    }

    if (password.trim() === "") {
      newErrorMessages.password = "Please enter a password.";
      logCreateAccountFailureToAnalytics("FIELDS_MISSING");
    }

    if (confirmPassword.trim() === "") {
      newErrorMessages.confirmPassword = "Please confirm your password.";
      logCreateAccountFailureToAnalytics("FIELDS_MISSING");
    }

    if (
      password.trim() !== "" &&
      confirmPassword.trim() !== "" &&
      confirmPassword.trim() !== password.trim()
    ) {
      newErrorMessages.confirmPassword = "Passwords do not match.";
      logCreateAccountFailureToAnalytics("NON_MATCHING_PASSWORDS");
    }

    if (!isTermsAccepted) {
      setErrorBox("Please accept the terms and conditions.");
      setErrorMessages(newErrorMessages);
      logCreateAccountFailureToAnalytics("NOT_ACCEPTED_TERMS");
      return false;
    }

    if (password.trim() !== "" && password.trim().length < 8) {
      setErrorBox("Passwords must be at least 8 characters.");
      setErrorMessages(newErrorMessages);
      logCreateAccountFailureToAnalytics("SHORT_PASSWORD");
      return false;
    }

    for (const key in newErrorMessages) {
      if (newErrorMessages[key] !== undefined) {
        setErrorMessages(newErrorMessages);
        return false;
      }
    }

    return true;
  }, [
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    password,
    confirmPassword,
    isTermsAccepted,
    errorMessages,
  ]);

  const createCustomerInAWSAndDb = useCallback(async () => {
    if (restaurant) {
      setIsLoading(true);

      try {
        const { response: auth } = await createNewUserInAWSAction(
          emailAddress,
          password,
          firstName,
          lastName,
        )(dispatch);

        const customerId = auth.userId;

        await createCustomerInDatabaseAction(
          customerId,
          restaurant.id,
          firstName,
          lastName,
          emailAddress,
          phoneNumber,
        )(dispatch);

        logCreateAccountSuccessToAnalytics(customerId, isRedirectToCart);

        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate(getMenuPath());
        }

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        const errorMessage = typeof e === "string" ? e : (e as Error).message;

        if (
          errorMessage.includes(
            "An account with the given email already exists.",
          )
        ) {
          logCreateAccountFailureToAnalytics("EMAIL_ALREADY_EXISTS");
          setErrorBox("An account with this email address already exists.");
        } else {
          logCreateAccountFailureToAnalytics("UNKNOWN_ERROR", errorMessage);
          captureManualSentryException(e as Error);
          setErrorBox("Something went wrong. Please try again.");
        }
      }

      setIsLoading(false);
    }
  }, [
    restaurant,
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    password,
    redirectUrl,
    dispatch,
    navigate,
  ]);

  if (!restaurant) {
    captureManualSentryException(
      new Error("restaurant is not defined in SignUp"),
    );

    return <div />;
  }

  return (
    <div className={styles.SignUp} data-testid="sign-up-container">
      <Image src={restaurant.logoUrl} alt="logo" className={styles.logo} />
      <h1
        className={styles.headingText}
      >{`Earn Rewards with ${restaurant.restaurantName}`}</h1>
      <TextInput
        className={styles.input}
        testId="first-name-input"
        label="First Name"
        autoComplete="given-name"
        value={firstName}
        onChangeText={(newText) => {
          setFirstName(newText);
          setErrorMessages({
            ...errorMessages,
            firstName: undefined,
          });
        }}
        placeholder="Enter your first name..."
        errorMessage={errorMessages.firstName}
      />
      <TextInput
        className={styles.input}
        testId="last-name-input"
        label="Last Name"
        autoComplete="family-name"
        value={lastName}
        onChangeText={(newText) => {
          setLastName(newText);
          setErrorMessages({
            ...errorMessages,
            lastName: undefined,
          });
        }}
        placeholder="Enter your last name..."
        errorMessage={errorMessages.lastName}
      />
      <TextInput
        className={styles.input}
        testId="email-address-input"
        label="Email Address"
        type="email"
        autoComplete="email"
        value={emailAddress}
        onChangeText={(newText) => {
          setEmailAddress(newText);
          setErrorMessages({
            ...errorMessages,
            emailAddress: undefined,
          });
        }}
        placeholder="Enter your email address..."
        errorMessage={errorMessages.emailAddress}
      />
      <TextInput
        className={styles.input}
        testId="phone-number-input"
        label="Phone Number"
        type="tel"
        autoComplete="tel"
        value={phoneNumber}
        onChangeText={(newText) => {
          setPhoneNumber(newText);
          setErrorMessages({
            ...errorMessages,
            phoneNumber: undefined,
          });
        }}
        placeholder="Enter your phone number..."
        errorMessage={errorMessages.phoneNumber}
      />
      <TextInput
        className={styles.input}
        testId="password-input"
        label="Password"
        type="password"
        autoComplete="new-password"
        value={password}
        onChangeText={(newText) => {
          setPassword(newText);
          setErrorMessages({
            ...errorMessages,
            password: undefined,
          });
        }}
        placeholder="Enter your password..."
        errorMessage={errorMessages.password}
      />
      <TextInput
        className={styles.input}
        testId="confirm-password-input"
        label="Confirm Password"
        type="password"
        autoComplete="new-password"
        value={confirmPassword}
        onChangeText={(newText) => {
          setConfirmPassword(newText);
          setErrorMessages({
            ...errorMessages,
            confirmPassword: undefined,
          });
        }}
        placeholder="Confirm your password..."
        errorMessage={errorMessages.confirmPassword}
      />
      <div className={styles.termsRow}>
        <Checkbox
          className={styles.checkbox}
          testId="terms-and-conditions-checkbox"
          checked={isTermsAccepted}
          onChange={setIsTermsAccepted}
        />
        <p className={styles.acceptTermsText}>
          {"I accept the "}
          <Link
            to={getTermsPath()}
            className={styles.link}
            data-testid="terms-and-conditions-link"
          >
            Terms and Conditions
          </Link>
          {` of creating a ${restaurant.restaurantName} account.`}
        </p>
      </div>
      {errorBox && (
        <div className={styles.errorBox}>
          <FontAwesomeIcon
            className={styles.errorIcon}
            icon={faTriangleExclamation}
          />
          <p className={styles.errorBoxText} data-testid="error-box-text">
            {errorBox}
          </p>
        </div>
      )}
      {isLoading ? (
        <ReactLoading
          type="spin"
          color={design.buttonColor}
          height={40}
          width={40}
        />
      ) : (
        <Button
          testId="create-account-button"
          className={styles.signUpButton}
          onClick={() => {
            setErrorBox(undefined);
            logCreateAccountInitiatedToAnalytics(isRedirectToCart);

            if (areFieldsValid()) {
              createCustomerInAWSAndDb();
            }
          }}
        >
          <h3 className={styles.buttonText}>Create Account</h3>
        </Button>
      )}
      <p className={styles.existingAccountText}>
        {"Already have an account? "}
        <Link
          to={redirectUrl ? getSignInPath(redirectUrl) : getSignInPath()}
          className={styles.link}
          data-testid="existing-account-link"
        >
          Sign In Now
        </Link>
      </p>
    </div>
  );
};
