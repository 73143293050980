import styles from "src/components/Footer/CustomSocialIcon/styles.module.scss";

interface ComponentProps {
  conatinerClassName?: string;
  testId?: string;
  socialMediaKey: string;
  socialMediaLink: string;
  onClick?: () => void;
}

export const CustomSocialIcon = ({
  conatinerClassName,
  socialMediaKey,
  socialMediaLink,
  testId,
  onClick,
}: ComponentProps) => {
  return (
    <a
      data-testid={testId}
      className={conatinerClassName}
      href={socialMediaLink}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      <img
        src={require(`../../../assets/${socialMediaKey}.webp`)}
        className={styles.CustomSocialIcon}
      />
    </a>
  );
};
