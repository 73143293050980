import styles from "src/components/BasicModal/styles.module.scss";
import Modal from "react-awesome-modal";
import { Button } from "src/components";
import ReactLoading from "react-loading";
import classNames from "classnames";
import { ReactNode } from "react";
import { useDesign } from "src/common/getDesign";
interface BasicModalProps {
  testId?: string;
  title: string;
  message: string | ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  isModalVisible: boolean;
  onClickOutside: () => void;
  isLoading?: boolean;
  height?: string;
  confirmText?: string;
}

export const BasicModal = ({
  testId,
  title,
  message,
  onConfirm,
  onCancel,
  isModalVisible,
  onClickOutside,
  isLoading,
  height = "200",
  confirmText = "Confirm",
}: BasicModalProps) => {
  const design = useDesign();
  return (
    <Modal
      visible={isModalVisible}
      width="300"
      height={height}
      effect="fadeInUp"
      onClickAway={onClickOutside}
    >
      <div className={styles.BasicModal} data-testid={testId}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.message} data-testid="message">
          {message}
        </p>
        {isLoading ? (
          <ReactLoading
            type="spin"
            color={design.buttonColor}
            height={35}
            width={35}
          />
        ) : (
          <div
            className={classNames(styles.buttonsContainer, {
              [styles.oneButton]: !onCancel,
            })}
          >
            {onCancel && (
              <Button
                secondary={true}
                className={styles.button}
                onClick={onCancel}
                testId="cancel-button"
              >
                <h3 className={styles.buttonText}>Cancel</h3>
              </Button>
            )}
            <Button
              className={styles.button}
              onClick={onConfirm}
              testId="confirm-button"
            >
              <h3 className={styles.buttonText}>{confirmText}</h3>
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
