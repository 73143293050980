// Actions associated with catering invoices
import { Dispatch } from "redux";
import { post } from "src/api/server";
import { CateringInvoiceFragment } from "src/state/cateringInvoice/types";

export enum CATERING_INVOICE_ACTION {
  GET_CATERING_INVOICE_FROM_DATABASE_ACTION = "GET_CATERING_INVOICE_FROM_DATABASE_ACTION",
}

export interface GetCateringInvoiceFromDatabaseAction {
  type: CATERING_INVOICE_ACTION.GET_CATERING_INVOICE_FROM_DATABASE_ACTION;
  response: CateringInvoiceFragment;
}

export const getCateringInvoiceFromDatabaseAction = (
  cateringInvoiceId: string,
) => {
  return async (dispatch: Dispatch<GetCateringInvoiceFromDatabaseAction>) => {
    const response = (await post({
      endpoint: "/catering/get_catering_invoice",
      params: {
        cateringInvoiceId,
      },
    })) as CateringInvoiceFragment;

    const action = {
      type: CATERING_INVOICE_ACTION.GET_CATERING_INVOICE_FROM_DATABASE_ACTION,
      response,
    } as GetCateringInvoiceFromDatabaseAction;

    dispatch(action);

    return action;
  };
};
