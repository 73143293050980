import classNames from "classnames";
import styles from "src/pages/Checkout/CheckoutOrderDetails/styles.module.scss";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "src/state/state";
import { CheckoutOrderDetailsOptions } from "src/pages/Checkout/CheckoutOrderDetails/CheckoutOrderDetailsOptions/CheckoutOrderDetailsOptions";
import { ItemFragment } from "src/state/item/types";
import { Button } from "src/components";
import { useNavigate } from "react-router-dom";
import { getReceiptPath } from "src/Router/routes";
import ReactLoading from "react-loading";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { CheckoutPriceDetails } from "src/pages/Checkout/CheckoutOrderDetails/CheckoutPriceDetails/CheckoutPriceDetails";
import { CartDealFragment, CartItemFragment } from "src/state/cart/types";
import { updateCustomerPointsBasedOnOrderAction } from "src/state/customer/actions";
import { clearCartAction } from "src/state/cart/actions";
import { captureManualSentryException } from "src/common/sentry";
import {
  logCheckoutFailureToAnalytics,
  logCheckoutInitiatedToAnalytics,
  logCheckoutSuccessToAnalytics,
} from "src/common/analytics";
import { isCartItemADeal } from "src/state/order/utils";
import { OrderDetailsCartItem } from "src/pages/Checkout/CheckoutOrderDetails/OrderDetailsCartItem/OrderDetailsCartItem";
import { updateGuestCustomerName } from "src/common/order";
import { completeCheckoutSessionAction } from "src/state/checkoutSession/actions";
import { stripeErrors } from "src/common/stripeErrors";
import { getCurrentEnvironment } from "src/config/getConfig";
import { animateScroll as scroll } from "react-scroll";
import { useDesign } from "src/common/getDesign";

interface CheckoutOrderDetailsProps {
  className?: string;
  areFieldsValid: () => boolean;
  nameForTheOrder: string;
  phoneForTheOrder: string;
}

export const CheckoutOrderDetails = ({
  className,
  areFieldsValid,
  nameForTheOrder,
  phoneForTheOrder,
}: CheckoutOrderDetailsProps) => {
  const [total, setTotal] = useState(0);
  const design = useDesign();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const elements = useElements();
  const stripe = useStripe();

  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const cart = useSelector((state: State) => state.cart);
  const items = useSelector((state: State) => state.items);
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const deals = useSelector(
    (state: State) => restaurant && state.deals[restaurant.id],
  );
  const checkoutSession = useSelector((state: State) => state.checkoutSession);

  const cartArray = useMemo(() => {
    return Object.values(cart);
  }, [cart]);

  const allItemsObject = useMemo(() => {
    let allItemsObject: { [itemId: string]: ItemFragment } = {};

    for (const categoryId in items) {
      const itemsInCategory = items[categoryId];
      allItemsObject = {
        ...allItemsObject,
        ...itemsInCategory,
      };
    }

    return allItemsObject;
  }, [items]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined,
  );

  const handlePayment = useCallback(async () => {
    if (areFieldsValid() === false) {
      return;
    }

    setIsLoading(true);
    setErrorMessage(undefined);

    if (stripe && elements && restaurant) {
      let error;

      const isGuestCustomer = !customer;

      logCheckoutInitiatedToAnalytics(
        customer?.id,
        checkoutSession.order.orderType,
        checkoutSession.order.pickupTime ? "LATER" : "ASAP",
        Object.values(cart).length,
        total,
      );

      if (isGuestCustomer) {
        await updateGuestCustomerName(
          checkoutSession.order.guestCustomerId as string,
          nameForTheOrder,
          phoneForTheOrder,
        );
      }

      if (total > 0) {
        const resultFromStripe = await stripe.confirmPayment({
          elements,
          redirect: "if_required",
          confirmParams: {
            return_url:
              getCurrentEnvironment() === "test"
                ? "https://joinplatter.com"
                : window.location.origin,
          },
        });

        error = resultFromStripe.error;
        resultFromStripe.paymentIntent;
      }

      if (error) {
        const { message } = error;

        setErrorMessage(message);

        if (!stripeErrors.includes(message)) {
          captureManualSentryException(message);
        }

        logCheckoutFailureToAnalytics(customer?.id, message);
      } else {
        logCheckoutSuccessToAnalytics(
          customer?.id,
          checkoutSession.order.orderType,
          checkoutSession.order.pickupTime ? "LATER" : "ASAP",
          Object.values(cart).length,
          total,
          checkoutSession.order.additionalNotes !== "",
        );

        // Reset points & clear cart
        if (!isGuestCustomer) {
          await updateCustomerPointsBasedOnOrderAction(
            customer,
            checkoutSession.order,
            restaurant.loyalty,
          )(dispatch);
        }
        await completeCheckoutSessionAction()(dispatch);
        await clearCartAction()(dispatch);

        navigate(getReceiptPath(checkoutSession.order.id));
        scroll.scrollToTop();
      }
    }

    setIsLoading(false);
  }, [
    areFieldsValid,
    nameForTheOrder,
    stripe,
    elements,
    restaurant,
    customer,
    checkoutSession,
    cart,
    total,
    setIsLoading,
    dispatch,
    navigate,
  ]);

  if (!items || !restaurant || !deals) {
    captureManualSentryException(
      new Error(
        "items or restaurant or deals is undefined in CheckoutOrderDetails",
      ),
    );
    return <div />;
  }

  return (
    <div
      className={classNames(styles.CheckoutOrderDetails, className)}
      data-testid="order-details"
    >
      <CheckoutOrderDetailsOptions />
      <div className={styles.cartItemsContainer}>
        {cartArray.map((cartItemOrDeal) => {
          if (isCartItemADeal(cartItemOrDeal)) {
            const cartDeal = cartItemOrDeal as CartDealFragment;

            return (
              <OrderDetailsCartItem
                key={cartDeal.id}
                name={deals[cartDeal.dealId].name}
                totalPrice={cartDeal.totalPrice}
                testId={`cart-item-summary-${cartDeal.dealId}`}
              />
            );
          } else {
            const cartItem = cartItemOrDeal as CartItemFragment;

            return (
              <OrderDetailsCartItem
                key={cartItem.itemId}
                name={allItemsObject[cartItem.itemId].name}
                totalPrice={cartItem.totalPrice}
                testId={`cart-item-summary-${cartItem.itemId}`}
              />
            );
          }
        })}
      </div>
      {checkoutSession.order.additionalNotes && (
        <div className={styles.additionalNotesContainer}>
          <h3 className={styles.additionalNotesLabel}>Additional Notes</h3>
          <p
            className={styles.additionalNotesText}
            data-testid="additional-notes"
          >
            {checkoutSession.order.additionalNotes}
          </p>
        </div>
      )}
      <CheckoutPriceDetails
        onTotalCalculated={(calculatedTotal: number) => {
          setTotal(calculatedTotal);
        }}
      />
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <ReactLoading
            type="spin"
            color={design.buttonColor}
            height={40}
            width={40}
          />
        </div>
      ) : (
        <Button
          testId="pay-button"
          className={styles.payButton}
          onClick={handlePayment}
        >
          <h3 className={styles.payText}>Pay</h3>
        </Button>
      )}
      {errorMessage && (
        <p data-testid="error-message" className={styles.errorMessage}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};
