import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { setClientConfigAction } from "src/state/clientConfig/actions";
import styles from "src/Router/styles.module.scss";
import {
  Menu,
  Item,
  SignIn,
  SignUp,
  Terms,
  Account,
  PersonalInfo,
  Cart,
  Checkout,
  Catering,
  ForgotPassword,
  ResetPassword,
  Deal,
  Receipt,
  CateringInvoice,
} from "src/pages";
import { useDispatch } from "react-redux";
import {
  Footer,
  Header,
  SelectLocationModal,
  SkeletonLoading,
} from "src/components";
import {
  getGenericMenuPath,
  getGenericItemPath,
  getGenericSignInPath,
  getGenericSignUpPath,
  getGenericTermsPath,
  getGenericAccountPath,
  getGenericPersonalInfoPath,
  getGenericCartPath,
  getGenericCheckoutPath,
  getGenericWildcardRoute,
  getGenericCateringPath,
  getGenericForgotPasswordPath,
  getGenericResetPasswordPath,
  getGenericDealPath,
  getGenericCategoryPath,
  getGenericReceiptPath,
  getGenericCateringInvoicePath,
} from "src/Router/routes";
import { getItemFromLocalStorage } from "src/common/localStorage";
import { getCustomerFromDatabaseAction } from "src/state/customer/actions";
import { logWebsiteVisitToAnalytics } from "src/common/analytics";
import { initializeState } from "src/state/initializeState";
import { selectLocationAction } from "src/state/location/actions";

export const Router = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isLocationSelectorVisible, setIsLocationSelectorVisible] =
    useState(false);

  const initializeAuth = useCallback(async () => {
    const userId = getItemFromLocalStorage("USER_ID");
    const refreshToken = getItemFromLocalStorage("REFRESH_TOKEN");

    if (userId && refreshToken) {
      await getCustomerFromDatabaseAction(userId)(dispatch);
    }
  }, [dispatch]);

  const initializeClientConfig = useCallback(async () => {
    const response = await setClientConfigAction()(dispatch);
    const restaurantId = response.response.restaurantId;

    const [initializeResponse] = await Promise.all([
      initializeState(restaurantId, dispatch),
      initializeAuth(),
    ]);

    const { restaurant } = initializeResponse;
    const currentPath = window.location.pathname;

    if (
      restaurant.locations.length === 1 ||
      currentPath.includes("catering-invoice")
    ) {
      selectLocationAction(restaurant.locations[0])(dispatch);
    } else {
      setIsLocationSelectorVisible(true);
    }

    setIsLoading(false);

    logWebsiteVisitToAnalytics();
  }, [dispatch, window.location.pathname]);

  useEffect(() => {
    initializeClientConfig();
  }, []);

  if (isLoading) {
    return (
      <div className={styles.Router} data-testid="router-loading">
        <div className={styles.loading}>
          <SkeletonLoading />
        </div>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <div className={styles.Router}>
        <Header />
        <div className={styles.content}>
          <Routes>
            <Route path={getGenericMenuPath()} element={<Menu />} />
            <Route path={getGenericCateringPath()} element={<Catering />} />
            <Route path={getGenericDealPath()} element={<Deal />} />
            <Route path={getGenericItemPath()} element={<Item />} />
            <Route path={getGenericSignInPath()} element={<SignIn />} />
            <Route
              path={getGenericForgotPasswordPath()}
              element={<ForgotPassword />}
            />
            <Route
              path={getGenericResetPasswordPath()}
              element={<ResetPassword />}
            />
            <Route path={getGenericSignUpPath()} element={<SignUp />} />
            <Route path={getGenericTermsPath()} element={<Terms />} />
            <Route path={getGenericAccountPath()} element={<Account />} />
            <Route
              path={getGenericPersonalInfoPath()}
              element={<PersonalInfo />}
            />
            <Route path={getGenericCartPath()} element={<Cart />} />
            <Route path={getGenericCheckoutPath()} element={<Checkout />} />
            <Route path={getGenericReceiptPath()} element={<Receipt />} />
            <Route
              path={getGenericCateringInvoicePath()}
              element={<CateringInvoice />}
            />
            <Route
              path={getGenericCategoryPath()}
              element={<Navigate replace to={getGenericMenuPath()} />}
            />
            <Route
              path={getGenericWildcardRoute()}
              element={<Navigate replace to={getGenericMenuPath()} />}
            />
          </Routes>
        </div>
        <Footer />
        <SelectLocationModal
          testId="root-select-location-modal"
          isOpen={isLocationSelectorVisible}
          onClose={() => setIsLocationSelectorVisible(false)}
        />
      </div>
    </BrowserRouter>
  );
};
