// Actions associated with restaurants
import { Dispatch } from "redux";
import { post } from "src/api/server";
import { deleteUserFromAWSCognito } from "src/common/auth";
import { CustomerFragment } from "src/state/customer/types";
import { OrderFragment } from "src/state/order/types";
import { LOYALTY_TYPE, LoyaltyFragment } from "src/common/types/Loyalty";

export enum CUSTOMER_ACTION {
  CREATE_CUSTOMER_IN_DATABASE_ACTION = "CREATE_CUSTOMER_IN_DATABASE_ACTION",
  UPDATE_CUSTOMER_IN_DATABASE_ACTION = "UPDATE_CUSTOMER_IN_DATABASE_ACTION",
  UPDATE_CUSTOMER_POINTS_BASED_ON_ORDER_ACTION = "UPDATE_CUSTOMER_POINTS_BASED_ON_ORDER_ACTION",
  GET_CUSTOMER_FROM_DATABASE_ACTION = "GET_CUSTOMER_FROM_DATABASE_ACTION",
  SIGN_OUT_CUSTOMER_ACTION = "SIGN_OUT_CUSTOMER_ACTION",
  DELETE_CUSTOMER_FROM_DATABASE_ACTION = "DELETE_CUSTOMER_FROM_DATABASE_ACTION",
}

export interface CreateCustomerInDatabaseAction {
  type: CUSTOMER_ACTION.CREATE_CUSTOMER_IN_DATABASE_ACTION;
  response: CustomerFragment;
}

export const createCustomerInDatabaseAction = (
  customerId: string,
  restaurantId: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
) => {
  return async (dispatch: Dispatch<CreateCustomerInDatabaseAction>) => {
    const response = await post({
      endpoint: "/customer/create",
      params: {
        id: customerId,
        restaurantId,
        firstName,
        lastName,
        email,
        phone,
      },
    });

    const action = {
      type: CUSTOMER_ACTION.CREATE_CUSTOMER_IN_DATABASE_ACTION,
      response,
    } as CreateCustomerInDatabaseAction;

    dispatch(action);

    return action;
  };
};

export interface UpdateCustomerInDatabaseAction {
  type: CUSTOMER_ACTION.UPDATE_CUSTOMER_IN_DATABASE_ACTION;
  response: CustomerFragment;
}

export const updateCustomerInDatabaseAction = (
  customerId: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
) => {
  return async (dispatch: Dispatch<UpdateCustomerInDatabaseAction>) => {
    const response = await post({
      endpoint: "/customer/update",
      params: {
        id: customerId,
        firstName,
        lastName,
        email,
        phone,
      },
    });

    const action = {
      type: CUSTOMER_ACTION.UPDATE_CUSTOMER_IN_DATABASE_ACTION,
      response,
    } as UpdateCustomerInDatabaseAction;

    dispatch(action);

    return action;
  };
};

export interface UpdateCustomerPointsBasedOnOrderAction {
  type: CUSTOMER_ACTION.UPDATE_CUSTOMER_POINTS_BASED_ON_ORDER_ACTION;
  pointsToSubtract: number;
}

export const updateCustomerPointsBasedOnOrderAction = (
  customer: CustomerFragment,
  order: OrderFragment,
  loyalty: LoyaltyFragment,
) => {
  return async (dispatch: Dispatch<UpdateCustomerPointsBasedOnOrderAction>) => {
    if (order.pointsUsedInDollars === 0 || loyalty.type === LOYALTY_TYPE.NONE) {
      return 0;
    }

    const numberOfPointsCustomerHas = customer.points;

    let pointsToSubtract = 0;

    const minimumDollarToBeAbleToApply =
      loyalty.pointsSystemAmountSpend as number;
    const minimumPointsToBeAbleToApply = minimumDollarToBeAbleToApply * 100;

    const pointsRequiredToFillSubtotal =
      Math.ceil(order.subtotal) * minimumPointsToBeAbleToApply;

    if (numberOfPointsCustomerHas < pointsRequiredToFillSubtotal) {
      pointsToSubtract =
        Math.floor(numberOfPointsCustomerHas / minimumPointsToBeAbleToApply) *
        minimumPointsToBeAbleToApply;
    } else {
      pointsToSubtract = pointsRequiredToFillSubtotal;
    }

    const action = {
      type: CUSTOMER_ACTION.UPDATE_CUSTOMER_POINTS_BASED_ON_ORDER_ACTION,
      pointsToSubtract,
    } as UpdateCustomerPointsBasedOnOrderAction;

    dispatch(action);

    return action;
  };
};

export interface GetCustomerFromDatabaseAction {
  type: CUSTOMER_ACTION.GET_CUSTOMER_FROM_DATABASE_ACTION;
  response: CustomerFragment;
}

export const getCustomerFromDatabaseAction = (customerId: string) => {
  return async (dispatch: Dispatch<GetCustomerFromDatabaseAction>) => {
    const response = (await post({
      endpoint: "/customer/get",
      params: {
        id: customerId,
      },
    })) as CustomerFragment;

    const action = {
      type: CUSTOMER_ACTION.GET_CUSTOMER_FROM_DATABASE_ACTION,
      response,
    } as GetCustomerFromDatabaseAction;

    dispatch(action);

    return action;
  };
};

export interface SignOutCustomerAction {
  type: CUSTOMER_ACTION.SIGN_OUT_CUSTOMER_ACTION;
}

export const signOutCustomerAction = () => {
  return async (dispatch: Dispatch<SignOutCustomerAction>) => {
    const action = {
      type: CUSTOMER_ACTION.SIGN_OUT_CUSTOMER_ACTION,
    } as SignOutCustomerAction;

    dispatch(action);

    return action;
  };
};

export interface DeleteCustomerFromDatabaseAction {
  type: CUSTOMER_ACTION.DELETE_CUSTOMER_FROM_DATABASE_ACTION;
}

export const deleteCustomerFromDatabaseAction = (customerId: string) => {
  return async (dispatch: Dispatch<DeleteCustomerFromDatabaseAction>) => {
    await post({
      endpoint: "/customer/delete",
      params: {
        id: customerId,
      },
    });

    await deleteUserFromAWSCognito(customerId);

    const action = {
      type: CUSTOMER_ACTION.DELETE_CUSTOMER_FROM_DATABASE_ACTION,
    } as DeleteCustomerFromDatabaseAction;

    dispatch(action);

    return action;
  };
};
