import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { captureManualSentryException } from "src/common/sentry";
import { useScrollToTop } from "src/common/useScrollToTop";
import { PageTitle, SkeletonLoading } from "src/components";
import styles from "src/pages/CateringInvoice/styles.module.scss";
import { State } from "src/state/state";
import { getCateringInvoiceFromDatabaseAction } from "src/state/cateringInvoice/actions";

type CateringInvoiceUrlParams = "cateringInvoiceId";

export const CateringInvoice = () => {
  useScrollToTop();

  const { cateringInvoiceId } = useParams<CateringInvoiceUrlParams>();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const cateringInvoice = useSelector(
    (state: State) =>
      cateringInvoiceId && state.cateringInvoices[cateringInvoiceId],
  );

  const fetchCateringInvoice = useCallback(async () => {
    if (cateringInvoiceId) {
      await getCateringInvoiceFromDatabaseAction(cateringInvoiceId)(dispatch);
    }

    setIsLoading(false);
  }, [cateringInvoice]);

  useEffect(() => {
    if (!cateringInvoice) {
      fetchCateringInvoice();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div className={styles.CateringInvoice}>
        <div className={styles.loading}>
          <SkeletonLoading />
        </div>
      </div>
    );
  }

  if (!cateringInvoice) {
    captureManualSentryException(
      new Error("cateringInvoice is not defined in CateringInvoice"),
    );
    return <div />;
  }

  return (
    <div
      className={styles.CateringInvoice}
      data-testid={`catering-invoice-container-${cateringInvoice.id}`}
    >
      <PageTitle
        title={`Catering Invoice #${cateringInvoice.cateringRequest.cateringNumber}`}
        testId="page-title"
      />
    </div>
  );
};
