import { roundToNearestCent } from "src/common/price";

export const getServiceFee = (
  subtotal: number,
  totalBeforeServiceFee: number,
  isDelivery: boolean,
  isOwnerManaged: boolean,
) => {
  if (isDelivery) {
    return 1.25;
  }

  let baseServiceFee = 0;

  if (subtotal <= 30) {
    baseServiceFee = 1.25;
  } else if (subtotal <= 50) {
    baseServiceFee = 2.25;
  } else if (subtotal <= 100) {
    baseServiceFee = 3.25;
  } else if (subtotal <= 150) {
    baseServiceFee = 4.25;
  } else {
    baseServiceFee = 5.25;
  }

  if (!isOwnerManaged) {
    const paymentProcessingFee =
      (totalBeforeServiceFee + baseServiceFee) * 0.029 + 0.3;
    const paymentProcessingFeeOnPaymentProcessingFee =
      paymentProcessingFee * 0.029;

    baseServiceFee +=
      paymentProcessingFee + paymentProcessingFeeOnPaymentProcessingFee;
  }

  return roundToNearestCent(baseServiceFee);
};
