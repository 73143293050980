import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getPersonalInfoPath,
  getSignInPath,
  getTermsPath,
} from "src/Router/routes";
import { Button, PageTitle, BasicModal } from "src/components";
import styles from "src/pages/Account/styles.module.scss";
import { State } from "src/state/state";
import { AccountItem } from "src/pages/Account/AccountItem/AccountItem";
import { signOutUser } from "src/common/auth";
import { signOutUserAction } from "src/state/auth/actions";
import {
  deleteCustomerFromDatabaseAction,
  signOutCustomerAction,
} from "src/state/customer/actions";
import { captureManualSentryException } from "src/common/sentry";
import {
  logDeleteAccountToAnalytics,
  logSignOutToAnalytics,
} from "src/common/analytics";
import { useScrollToTop } from "src/common/useScrollToTop";
import { clearOrdersFromStateAction } from "src/state/order/actions";

export const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useScrollToTop();

  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );

  const [isDeleteAccountModalVisible, setIsDeleteAccountModalVisible] =
    useState(false);
  const [isSignOutModalVisible, setIsSignOutModalVisible] = useState(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);

  const handleSignOut = useCallback(async () => {
    setIsSigningOut(true);

    if (customer) {
      await Promise.all([
        signOutUser(),
        signOutUserAction()(dispatch),
        clearOrdersFromStateAction()(dispatch),
        signOutCustomerAction()(dispatch),
      ]);

      logSignOutToAnalytics(customer.id);
    }

    setIsSigningOut(false);
    setIsSignOutModalVisible(false);
    navigate(getSignInPath());
  }, [customer, dispatch, navigate]);

  const handleDeleteAccount = useCallback(async () => {
    setIsDeletingAccount(true);

    if (customer) {
      await Promise.all([
        signOutUser(),
        signOutUserAction()(dispatch),
        clearOrdersFromStateAction()(dispatch),
        signOutCustomerAction()(dispatch),
      ]);
      await deleteCustomerFromDatabaseAction(customer.id)(dispatch);
      logDeleteAccountToAnalytics(customer.id);
    }

    setIsDeletingAccount(false);
    setIsDeleteAccountModalVisible(false);
    navigate(getSignInPath());
  }, [customer, dispatch, navigate]);

  useEffect(() => {
    if (!customer) {
      navigate(getSignInPath());
    }
  }, []);

  if (!customer) {
    captureManualSentryException(
      new Error("Customer is not defined in Account"),
    );
    return <div />;
  }

  return (
    <div className={styles.Account} data-testid="account-container">
      <PageTitle title="Your Account" className={styles.title} />
      <AccountItem
        testId="personal-info-link"
        className={styles.accountItem}
        title="Personal Info"
        subtitle="Edit your name, email address, and more..."
        path={getPersonalInfoPath()}
      />
      <AccountItem
        testId="terms-and-conditions-link"
        className={styles.accountItem}
        title="Terms & Conditions"
        subtitle="View website usage terms and conditions..."
        path={getTermsPath()}
      />
      <AccountItem
        testId="delete-account-button"
        className={styles.accountItem}
        title="Delete Account"
        subtitle="Delete all account information..."
        onClick={() => setIsDeleteAccountModalVisible(true)}
      />
      <Button
        data-testid="sign-out-button"
        secondary={true}
        className={styles.signOutButton}
        onClick={() => setIsSignOutModalVisible(true)}
      >
        <h2 className={styles.signOutText}>Sign Out</h2>
      </Button>
      <BasicModal
        testId="sign-out-modal"
        isModalVisible={isSignOutModalVisible}
        onClickOutside={() => setIsSignOutModalVisible(false)}
        onCancel={() => setIsSignOutModalVisible(false)}
        onConfirm={handleSignOut}
        title="Sign Out"
        message="Are you sure you want to sign out?"
        isLoading={isSigningOut}
      />
      <BasicModal
        testId="delete-account-modal"
        isModalVisible={isDeleteAccountModalVisible}
        onClickOutside={() => setIsDeleteAccountModalVisible(false)}
        onCancel={() => setIsDeleteAccountModalVisible(false)}
        onConfirm={handleDeleteAccount}
        title="Delete Account"
        message="Are you sure you want to delete your account?"
        isLoading={isDeletingAccount}
      />
    </div>
  );
};
