import { Provider } from "react-redux";
import { store } from "src/state/store";
import { Router } from "src/Router/Router";
import { ThemeProvider } from "src/themeProvider";

export const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </Provider>
  );
};
