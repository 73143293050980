import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import styles from "src/pages/Deal/styles.module.scss";
import { State } from "src/state/state";
import classNames from "classnames";
import { Button, Image } from "src/components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getMenuPath } from "src/Router/routes";
import ReactLoading from "react-loading";
import { captureManualSentryException } from "src/common/sentry";
import { DealPicker } from "src/pages/Deal/DealPicker/DealPicker";
import { ItemFragment } from "src/state/item/types";
import { CartItemOptionsSelected } from "src/state/cart/types";
import {
  logAddToCartToAnalytics,
  logOneItemAddedToCartToAnalytics,
} from "src/common/analytics";
import { addDealToCartAction } from "src/state/cart/actions";
import {
  getItemPriceAfterDealApplied,
  getOptionValuePrice,
} from "src/common/price";
import { useScrollToTop } from "src/common/useScrollToTop";
import { Pricing } from "src/pages/Deal/Pricing/Pricing";
import { DEAL_TYPE } from "src/state/deal/types";
import { isNowWithinDealScheduleHours } from "src/common/date";
import { useDesign } from "src/common/getDesign";

type DealUrlParams = "dealId";

export const Deal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useScrollToTop();
  const design = useDesign();
  const { dealId } = useParams<DealUrlParams>();

  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const deal = useSelector(
    (state: State) =>
      restaurant &&
      dealId &&
      state.deals[restaurant.id] &&
      state.deals[restaurant.id][dealId],
  );
  const cartLength = useSelector(
    (state: State) => Object.values(state.cart).length,
  );
  const options = useSelector(
    (state: State) => restaurant && state.options[restaurant.id],
  );

  const [itemsSelected, setItemsSelected] = useState<
    (ItemFragment | undefined)[]
  >([]);
  const [optionsSelected, setOptionsSelected] = useState<
    CartItemOptionsSelected[]
  >([]);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  const areAllItemsSelected = useMemo(() => {
    if (deal) {
      const selectedItems = itemsSelected.filter((item) => item);
      return selectedItems.length === deal.entityIds.length;
    }

    return false;
  }, [itemsSelected, deal]);

  const areAllRequiredOptionsSelected = useMemo(() => {
    for (let i = 0; i < itemsSelected.length; i++) {
      const itemSelected = itemsSelected[i];
      const thisItemsOptionsSelected = optionsSelected[i];

      if (!itemSelected || !thisItemsOptionsSelected || !options) {
        return false;
      }

      const itemOptionsArray = Object.values(options).filter((option) =>
        option.itemIds.includes(itemSelected.id),
      );

      if (!itemOptionsArray) {
        return false;
      }

      const requiredOptions = itemOptionsArray.filter(
        (option) => option.minSelection > 0,
      );

      for (const requiredOption of requiredOptions) {
        if (
          !thisItemsOptionsSelected[requiredOption.id] ||
          thisItemsOptionsSelected[requiredOption.id].length <
            requiredOption.minSelection
        ) {
          return false;
        }
      }
    }

    return true;
  }, [itemsSelected, options, optionsSelected]);

  const addDealToCart = useCallback(async () => {
    if (deal) {
      const itemIdsSelected: string[] = [];
      const priceBeforeDealBeforeOptions: number[] = [];
      const priceAfterDealBeforeOptions: number[] = [];
      const priceAfterDealAfterOptions: number[] = [];

      for (let i = 0; i < itemsSelected.length; i++) {
        const item = itemsSelected[i] as ItemFragment;
        const options = optionsSelected[i] as CartItemOptionsSelected;
        const priceOfOptions = getOptionValuePrice(options);

        const thisItemPriceAfterDeal = getItemPriceAfterDealApplied(
          deal,
          item,
          i,
        );

        itemIdsSelected.push(item.id);
        priceBeforeDealBeforeOptions.push(item.price);
        priceAfterDealBeforeOptions.push(thisItemPriceAfterDeal);
        priceAfterDealAfterOptions.push(
          thisItemPriceAfterDeal + priceOfOptions,
        );
      }

      const totalPriceOfCartItem = priceAfterDealAfterOptions.reduce(
        (acc, price) => acc + price,
        0,
      );

      await addDealToCartAction(
        deal.id,
        optionsSelected as CartItemOptionsSelected[],
        itemIdsSelected,
        priceBeforeDealBeforeOptions,
        priceAfterDealBeforeOptions,
        priceAfterDealAfterOptions,
        totalPriceOfCartItem,
      )(dispatch);

      if (cartLength === 0) {
        logOneItemAddedToCartToAnalytics(customer?.id);
      }

      logAddToCartToAnalytics(
        undefined,
        1,
        totalPrice,
        deal.type,
        customer?.id,
      );
    }
  }, [deal, totalPrice, optionsSelected, itemsSelected, customer, dispatch]);

  const handleAddDealToCart = useCallback(async () => {
    setIsAddingToCart(true);

    if (deal) {
      setIsLoading(true);

      await addDealToCart();

      setIsLoading(false);
    }

    // Simulate loading for 1 seconds
    setTimeout(async () => {
      navigate(getMenuPath());
      setIsAddingToCart(false);
    }, 1000);
  }, [deal, optionsSelected, itemsSelected, dispatch]);

  useEffect(() => {
    if (deal && deal.type === DEAL_TYPE.COMBO) {
      setTotalPrice(deal.dealValue);
    }
  }, []);

  if (!restaurant || !dealId || !options) {
    captureManualSentryException(
      new Error("restaurant, dealId or options is not defined in Deal"),
    );
    return <div />;
  }

  if (!deal || !isNowWithinDealScheduleHours(deal.dealSchedule)) {
    return (
      <div
        className={styles.DealNotFound}
        data-testid={`deal-not-found-container-${dealId}`}
      >
        <h1 className={styles.errorMessage}>
          This deal is no longer available.
        </h1>
        <Button
          data-testid="back-to-menu-button"
          secondary={true}
          className={styles.backToMenuButton}
          onClick={() => navigate(getMenuPath())}
        >
          <h2 className={styles.mainMenuText}>Main Menu</h2>
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.Deal} data-testid={`deal-container-${dealId}`}>
      <div className={styles.linksContainer}>
        <Link
          to={getMenuPath()}
          className={styles.link}
          data-testid="deals-link"
        >
          {"Deals"}
        </Link>
        <h4 className={styles.linkSpacer}>/</h4>
        <h4 className={classNames(styles.link, styles.blackLink)}>
          {deal.name}
        </h4>
      </div>
      <div className={styles.dealContainer}>
        <div className={styles.dealImageContainer}>
          <Image
            src={deal.imageUrl || restaurant.logoUrl}
            alt={deal.name}
            className={classNames(styles.image, {
              [styles.logoImage]: deal.imageUrl === undefined,
            })}
          />
        </div>
        <div className={styles.dealDetailsContainer}>
          <div data-testid={"deal-details"}>
            <h1 className={styles.dealName} data-testid="title">
              {deal.name}
            </h1>
          </div>
          <DealPicker
            deal={deal}
            onItemsSelected={setItemsSelected}
            onTotalPriceChange={setTotalPrice}
            onItemOptionsSelected={setOptionsSelected}
          />
          {areAllItemsSelected && (
            <Pricing
              deal={deal}
              itemsSelected={itemsSelected as ItemFragment[]}
              itemOptionsSelected={optionsSelected}
            />
          )}
        </div>
      </div>
      <div className={styles.addToCartBanner}>
        <h1 className={styles.addToCartDealName}>{deal.name}</h1>
        {isAddingToCart ? (
          <div className={styles.addToCartLoading}>
            <ReactLoading
              type="spin"
              color={design.buttonColor}
              height={40}
              width={40}
            />
          </div>
        ) : (
          <Button
            testId="add-to-cart-button"
            className={styles.addToCartButton}
            onClick={() => {
              if (restaurant.restaurantSettings.isOnlineOrderingEnabled) {
                handleAddDealToCart();
              } else {
                window.open(
                  `tel://${restaurant.contactPhoneNumber};`,
                  "_blank",
                );
              }
            }}
            disabled={
              (restaurant.restaurantSettings.isOnlineOrderingEnabled &&
                isLoading) ||
              !areAllRequiredOptionsSelected ||
              !areAllItemsSelected
            }
          >
            <h3
              data-testid="total-price"
              className={styles.addToCartButtonText}
            >
              {restaurant.restaurantSettings.isOnlineOrderingEnabled
                ? `Add to Cart • $${
                    areAllItemsSelected || deal.type === DEAL_TYPE.COMBO
                      ? totalPrice.toFixed(2)
                      : "0.00"
                  }`
                : "Call to Order"}
            </h3>
          </Button>
        )}
      </div>
    </div>
  );
};
