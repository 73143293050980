import { useState } from "react";

export const useMockPlacesService = () => {
  const mockPlaces = [
    "11811 NE 128th St, Kirkland, WA, USA",
    "933 W 3rd Ave, Spokane, WA, USA",
    "1234 1st Ave, Seattle, WA, USA",
    "Los Angeles, CA, USA",
  ];

  const [isPlacePredictionsLoading, setIsPlacePredictionsLoading] =
    useState<boolean>(false);
  const [placePredictions, setPlacePredictions] = useState<
    { description: string }[]
  >([]);

  const getPlacePredictions = async (value: { input: string }) => {
    setIsPlacePredictionsLoading(true);

    setTimeout(() => {
      const results = mockPlaces.filter((place) =>
        place.toLowerCase().includes(value.input.toLowerCase()),
      );
      setPlacePredictions(results.map((description) => ({ description })));
      setIsPlacePredictionsLoading(false);
    }, 1000);
  };

  return {
    getPlacePredictions,
    isPlacePredictionsLoading,
    placePredictions,
  };
};
