import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styles from "src/components/Header/styles.module.scss";
import { Image } from "src/components";
import { State } from "src/state/state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUser,
  faBagShopping,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import {
  getAccountPath,
  getCartPath,
  getCateringPath,
  getMenuPath,
  getSignInPath,
} from "src/Router/routes";
import { OrderBanner } from "src/components/Header/OrderBanner/OrderBanner";
import { captureManualSentryException } from "src/common/sentry";
import { getAllItemsObjectFromState } from "src/state/item/utils";
import { Drawer } from "@mui/material";
import { logViewCartClickedToAnalytics } from "src/common/analytics";
import { isNowWithinOpenHoursOfOperation } from "src/common/date";
import { HoursOfOperationMode } from "src/common/types/HoursOfOperation";
import { DEAL_VALUE_TYPE } from "src/state/deal/types";

export const Header = () => {
  const location = useLocation();

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const restaurantWideDiscount = useSelector(
    (state: State) =>
      restaurant && state.restaurantWideDiscounts[restaurant.id],
  );
  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const cart = useSelector((state: State) => state.cart);
  const items = useSelector((state: State) =>
    getAllItemsObjectFromState(state.items),
  );
  const selectedLocation = useSelector(
    (state: State) => state.location.selectedLocation,
  );

  const cartAsArray = useMemo(() => Object.values(cart), [cart]);
  const cartSize = useMemo(() => {
    return cartAsArray.length;
  }, [cartAsArray]);
  const cartSubTotal = useMemo(
    () => cartAsArray && cartAsArray.reduce((a, v) => a + v.totalPrice, 0),
    [cartAsArray],
  );

  const currentPathName = useMemo(() => location.pathname, [location.pathname]);

  const headerItems: {
    name: string;
    link: string;
    testId: string;
  }[] = useMemo(() => {
    const baseHeaderItems = [
      {
        name: "Menu",
        link: getMenuPath(),
        testId: "menu-tab",
      },
    ];

    const itemsAsArray = Object.values(items);

    const hasCateredItems = itemsAsArray.some((item) => item.isCateringItem);
    const hasCateringForm =
      restaurant?.restaurantSettings.isCateringSchedulingFormEnabled;

    if (hasCateredItems || hasCateringForm) {
      baseHeaderItems.push({
        name: "Catering",
        link: getCateringPath(),
        testId: "catering-tab",
      });
    }

    return baseHeaderItems;
  }, [items]);

  if (!restaurant) {
    captureManualSentryException(
      new Error("restaurant is undefined in Header"),
    );
    return <div />;
  }

  return (
    <>
      {selectedLocation &&
      !isNowWithinOpenHoursOfOperation(selectedLocation.hoursOfOperation) ? (
        <div className={styles.banner} data-testid={"closed-banner"}>
          <p className={styles.bannerText}>Restaurant is currently closed</p>
        </div>
      ) : restaurant.restaurantSettings.isOnlineOrderingEnabled &&
        selectedLocation &&
        (selectedLocation.hoursOfOperation.modeSelected ===
          HoursOfOperationMode.MANUALLY_CLOSED ||
          selectedLocation.hoursOfOperation.modeSelected ===
            HoursOfOperationMode.CLOSED_UNTIL_X) ? (
        <div className={styles.banner} data-testid={"manually-closed-banner"}>
          <p className={styles.bannerText}>
            Not currently accepting online orders
          </p>
        </div>
      ) : restaurantWideDiscount ? (
        <div className={styles.banner}>
          <p
            className={styles.bannerText}
            data-testid={"restaurant-wide-discount-banner"}
          >
            {restaurantWideDiscount.dealValueType === DEAL_VALUE_TYPE.PERCENT
              ? `${restaurantWideDiscount.dealValue}% OFF ALL ITEMS AT CHECKOUT`
              : `$${restaurantWideDiscount.dealValue} OFF ALL ITEMS AT CHECKOUT`}
          </p>
        </div>
      ) : restaurant.restaurantDesign.announcementBar !== "" ? (
        <div className={styles.banner}>
          <p className={styles.bannerText} data-testid={"announcement-bar"}>
            {restaurant.restaurantDesign.announcementBar}
          </p>
        </div>
      ) : null}
      {restaurant.restaurantSettings.isOnlineOrderingEnabled && <OrderBanner />}
      <div className={styles.Header} data-testid="header">
        <Drawer
          PaperProps={{
            sx: {
              width: "50%",
            },
          }}
          anchor="left"
          open={isMobileDrawerOpen}
          onClose={() => {
            setIsMobileDrawerOpen(false);
          }}
        >
          <div className={styles.drawerContainer} data-testid="mobile-drawer">
            <Image
              src={restaurant.logoUrl}
              alt="logo"
              className={styles.drawerLogo}
            />
            {headerItems.map((item) => (
              <Link
                className={classNames(styles.drawerItem, {
                  [styles.drawerItemActive]: currentPathName === item.link,
                })}
                data-testid={item.testId}
                to={item.link}
                key={item.link}
                onClick={() => setIsMobileDrawerOpen(false)}
              >
                <h3>{item.name}</h3>
              </Link>
            ))}
            {restaurant.restaurantSettings.isOnlineOrderingEnabled && (
              <Link
                className={classNames(styles.drawerItem, {
                  [styles.drawerItemActive]:
                    currentPathName === getAccountPath(),
                })}
                data-testid={"account-tab"}
                to={customer ? getAccountPath() : getSignInPath()}
                onClick={() => setIsMobileDrawerOpen(false)}
              >
                <h3>Account</h3>
              </Link>
            )}
          </div>
        </Drawer>
        <div
          data-testid="mobile-drawer-button"
          className={styles.HeaderLeft}
          onClick={() => {
            setIsMobileDrawerOpen(true);
          }}
        >
          <FontAwesomeIcon className={styles.bars} size={"2x"} icon={faBars} />
        </div>
        <div className={styles.HeaderCenter}>
          <Link
            to={getMenuPath()}
            className={styles.logoContainer}
            data-testid="logo"
          >
            <Image
              src={restaurant.logoUrl}
              alt="logo"
              className={styles.restaurantLogo}
            />
          </Link>
          <div className={styles.headerItems}>
            {headerItems.map((item) => (
              <Link
                className={classNames(styles.headerItem, {
                  [styles.headerItemActive]: currentPathName === item.link,
                })}
                data-testid={item.testId}
                to={item.link}
                key={item.link}
              >
                <h3>{item.name}</h3>
              </Link>
            ))}
          </div>
        </div>
        <div className={styles.HeaderRight}>
          {restaurant.restaurantSettings.isOnlineOrderingEnabled && (
            <>
              <Link
                className={styles.accountLink}
                to={customer ? getAccountPath() : getSignInPath()}
                data-testid={"account-icon"}
              >
                <FontAwesomeIcon className={styles.icon} icon={faCircleUser} />
              </Link>
              <Link
                to={getCartPath()}
                className={styles.iconLink}
                onClick={() => {
                  logViewCartClickedToAnalytics(
                    customer?.id,
                    cartSize,
                    cartSubTotal,
                  );
                }}
              >
                {cartSize > 0 ? (
                  <div className={styles.checkoutButton}>
                    <p
                      data-testid="checkout-text"
                      className={styles.checkoutText}
                    >{`Checkout (${cartSize})`}</p>
                  </div>
                ) : (
                  <div data-testid={"cart-icon"}>
                    <FontAwesomeIcon
                      className={styles.icon}
                      icon={faBagShopping}
                    />
                  </div>
                )}
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};
