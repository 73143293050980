// Constants reused across the app
import { ORDER_STATUS } from "src/state/order/types";

export const orderStatusText = {
  [ORDER_STATUS.NEW_ORDER]: "Received",
  [ORDER_STATUS.IN_PROGRESS]: "In Progress",
  [ORDER_STATUS.READY_FOR_PICKUP]: "Ready for Pickup",
  [ORDER_STATUS.EN_ROUTE_TO_CUSTOMER]: "On the Way",
  [ORDER_STATUS.CANCELLED]: "Cancelled",
  [ORDER_STATUS.COMPLETE]: "Completed",
};
