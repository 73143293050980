import styles from "src/components/Checkbox/styles.module.scss";
import classNames from "classnames";

interface CheckboxProps {
  className?: string;
  testId?: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}

export const Checkbox = ({
  className,
  testId,
  checked,
  onChange,
  disabled,
}: CheckboxProps) => {
  return (
    <div
      className={classNames(styles.checkboxContainer, className, {
        [styles.disabled]: disabled,
      })}
    >
      <input
        className={classNames(styles.Checkbox)}
        data-testid={testId}
        type="checkbox"
        checked={checked}
        onChange={(event) =>
          onChange ? onChange(event.target.checked) : undefined
        }
        disabled={disabled}
      />
      <div
        className={classNames(styles.customCheckbox, {
          [styles.checked]: checked,
        })}
      />
    </div>
  );
};
