import { Button, Card, TextInput } from "src/components";
import { useSelector } from "react-redux";
import { State } from "src/state/state";
import { useMemo, useState, useCallback } from "react";
import styles from "src/pages/Catering/styles.module.scss";
import { getItemPath } from "src/Router/routes";
import { captureManualSentryException } from "src/common/sentry";
import { getAllItemsObjectFromState } from "src/state/item/utils";
import { CategoryFragment } from "src/state/category/types";
import { useScrollToTop } from "src/common/useScrollToTop";
import ReactLoading from "react-loading";
import {
  isValidEmailAddress,
  isValidPhoneNumber,
  isValidPositiveNumber,
} from "src/common/validation";
import {
  createCateringFormSubmission,
  createCateringRequestInDatabase,
} from "src/common/catering";
import { formatPhoneNumber } from "src/common/phone";
import {
  logCateringFormSubmitErrorToAnalytics,
  logCateringFormSubmittedToAnalytics,
} from "src/common/analytics";
import { useDesign } from "src/common/getDesign";
import { getCurrentEnvironment, getEnvVariable } from "src/config/getConfig";
import { useMockPlacesService } from "src/__mocks__/useMockPlacesService";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import classNames from "classnames";
export interface CateringFormFieldsErrors {
  name: string | undefined;
  emailAddress: string | undefined;
  phoneNumber: string | undefined;
  estimatedGuestCount: string | undefined;
  estimatedBudget: string | undefined;
  eventDate: string | undefined;
  eventLocation: string | undefined;
}

export const Catering = () => {
  useScrollToTop();
  const design = useDesign();
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    getCurrentEnvironment() === "test"
      ? useMockPlacesService()
      : usePlacesAutocompleteService({
          apiKey: getEnvVariable("GOOGLE_MAPS_API_KEY"),
        });

  const restaurant = useSelector(
    (state: State) => state.restaurants.currentRestaurant,
  );
  const customer = useSelector(
    (state: State) => state.customers.currentCustomer,
  );
  const categories = useSelector(
    (state: State) => restaurant && state.categories[restaurant.id],
  );
  const items = useSelector((state: State) => state.items);
  const itemsObject = useSelector((state: State) =>
    getAllItemsObjectFromState(state.items),
  );

  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [estimatedGuestCount, setEstimatedGuestCount] = useState("");
  const [estimatedBudget, setEstimatedBudget] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventLocationSearch, setEventLocationSearch] = useState("");
  const [selectedEventLocation, setSelectedEventLocation] = useState("");
  const [notes, setNotes] = useState("");
  const [errorMessages, setErrorMessages] = useState<CateringFormFieldsErrors>({
    name: undefined,
    emailAddress: undefined,
    phoneNumber: undefined,
    estimatedGuestCount: undefined,
    estimatedBudget: undefined,
    eventDate: undefined,
    eventLocation: undefined,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const categoriesWithCateringItems = useMemo(() => {
    if (categories) {
      const categoriesArray: CategoryFragment[] = [];

      for (const itemId in itemsObject) {
        const item = itemsObject[itemId];

        if (item.isCateringItem) {
          const category = categories[item.categoryId];
          // add category if it hasn't been added yet
          if (!categoriesArray.some((c) => c.id === category.id)) {
            categoriesArray.push(category);
          }
        }
      }

      return categoriesArray;
    }
  }, [categories, itemsObject]);

  const showAddressSearchResults = useMemo(() => {
    return (
      placePredictions.length > 0 &&
      !isPlacePredictionsLoading &&
      !selectedEventLocation &&
      !errorMessages.eventLocation &&
      eventLocationSearch.trim() !== ""
    );
  }, [
    placePredictions,
    isPlacePredictionsLoading,
    selectedEventLocation,
    errorMessages.eventLocation,
    eventLocationSearch,
  ]);

  const areFieldsValid = useCallback(() => {
    const newErrorMessages = {
      ...errorMessages,
    };

    if (name.trim() === "") {
      newErrorMessages.name = "Please enter your name.";
    }

    if (emailAddress.trim() === "") {
      newErrorMessages.emailAddress = "Please enter an email address.";
    }

    if (
      emailAddress.trim() !== "" &&
      !isValidEmailAddress(emailAddress.trim())
    ) {
      newErrorMessages.emailAddress = "Please enter a valid email address.";
    }

    if (phoneNumber.trim() === "" || !isValidPhoneNumber(phoneNumber.trim())) {
      newErrorMessages.phoneNumber = "Please enter a valid phone number.";
    }

    if (
      estimatedGuestCount.trim() === "" ||
      !isValidPositiveNumber(estimatedGuestCount)
    ) {
      newErrorMessages.estimatedGuestCount = "Please enter a valid number.";
    }

    if (
      estimatedBudget.trim() === "" ||
      !isValidPositiveNumber(estimatedBudget)
    ) {
      newErrorMessages.estimatedBudget = "Please enter a valid number.";
    }

    if (eventDate.trim() === "") {
      newErrorMessages.eventDate = "Please enter the event date.";
    }

    if (selectedEventLocation.trim() === "") {
      newErrorMessages.eventLocation = "Please enter the event location.";
    }

    logCateringFormSubmitErrorToAnalytics(errorMessages, customer?.id);

    for (const key in newErrorMessages) {
      if (newErrorMessages[key] !== undefined) {
        setErrorMessages(newErrorMessages);
        return false;
      }
    }

    return true;
  }, [
    customer,
    name,
    emailAddress,
    phoneNumber,
    estimatedGuestCount,
    estimatedBudget,
    eventDate,
    selectedEventLocation,
    errorMessages,
  ]);

  const handleCreatingCateringRequest = useCallback(async () => {
    setIsLoading(true);

    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    const split = eventDate.split("-");
    const year = split[0];
    const month = split[1];
    const day = split[2];

    const newDate = new Date();
    newDate.setFullYear(parseInt(year));
    newDate.setMonth(parseInt(month) - 1);
    newDate.setDate(parseInt(day));
    newDate.setHours(8);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    if (restaurant) {
      await createCateringRequestInDatabase(
        restaurant.id,
        name,
        companyName,
        emailAddress,
        formattedPhoneNumber,
        parseFloat(estimatedGuestCount),
        parseFloat(estimatedBudget),
        newDate.toISOString(),
        selectedEventLocation,
        notes,
      );
    }

    setIsLoading(false);
    setIsSubmitSuccess(true);
    logCateringFormSubmittedToAnalytics(
      name,
      emailAddress,
      formattedPhoneNumber,
      estimatedGuestCount,
      estimatedBudget,
      eventDate,
      selectedEventLocation,
      notes,
      companyName,
    );

    setName("");
    setCompanyName("");
    setEmailAddress("");
    setPhoneNumber("");
    setEstimatedGuestCount("");
    setEstimatedBudget("");
    setEventDate("");
    setEventLocationSearch("");
    setSelectedEventLocation("");
    setNotes("");
  }, [
    restaurant,
    name,
    companyName,
    emailAddress,
    phoneNumber,
    estimatedGuestCount,
    estimatedBudget,
    eventDate,
    eventLocationSearch,
    selectedEventLocation,
    notes,
  ]);

  /**
   * @deprecated
   */
  const handleEmailCateringFormToRestaurant = useCallback(async () => {
    setIsLoading(true);

    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    const split = eventDate.split("-");
    const year = split[0];
    const month = split[1];
    const day = split[2];

    const newDate = new Date();
    newDate.setFullYear(parseInt(year));
    newDate.setMonth(parseInt(month) - 1);
    newDate.setDate(parseInt(day));
    newDate.setHours(8);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    if (restaurant) {
      await createCateringFormSubmission(
        restaurant.id,
        name,
        emailAddress,
        formattedPhoneNumber,
        estimatedGuestCount,
        estimatedBudget,
        newDate.toISOString(),
        selectedEventLocation,
        notes,
      );
    }

    setIsLoading(false);
    setIsSubmitSuccess(true);
    logCateringFormSubmittedToAnalytics(
      name,
      emailAddress,
      formattedPhoneNumber,
      estimatedGuestCount,
      estimatedBudget,
      eventDate,
      selectedEventLocation,
      notes,
      companyName,
    );

    setName("");
    setCompanyName("");
    setEmailAddress("");
    setPhoneNumber("");
    setEstimatedGuestCount("");
    setEstimatedBudget("");
    setEventDate("");
    setEventLocationSearch("");
    setSelectedEventLocation("");
    setNotes("");
  }, [
    name,
    companyName,
    emailAddress,
    phoneNumber,
    estimatedGuestCount,
    estimatedBudget,
    eventDate,
    eventLocationSearch,
    selectedEventLocation,
    notes,
  ]);

  if (!restaurant || !categories || !categoriesWithCateringItems) {
    captureManualSentryException(
      new Error(
        "restaurant, categories, or categoriesWithCateringItems is undefined in Catering",
      ),
    );
    return <div />;
  }

  return (
    <div className={styles.Catering} data-testid="catering-container">
      {restaurant.restaurantSettings.isCateringSchedulingFormEnabled && (
        <div data-testid="catering-form">
          <h1 className={styles.sectionHeader}>Catering Request</h1>
          <TextInput
            className={styles.input}
            testId="name-input"
            label="Name*"
            autoComplete="given-name"
            value={name}
            onChangeText={(newText) => {
              setIsSubmitSuccess(false);
              setName(newText);
              setErrorMessages({
                ...errorMessages,
                name: undefined,
              });
            }}
            placeholder="Enter your name..."
            errorMessage={errorMessages.name}
          />
          <TextInput
            className={styles.input}
            testId="email-input"
            label="Email Address*"
            autoComplete="email"
            value={emailAddress}
            onChangeText={(newText) => {
              setIsSubmitSuccess(false);
              setEmailAddress(newText);
              setErrorMessages({
                ...errorMessages,
                emailAddress: undefined,
              });
            }}
            placeholder="Enter your email address..."
            errorMessage={errorMessages.emailAddress}
          />
          <TextInput
            className={styles.input}
            testId="phone-input"
            label="Phone Number*"
            autoComplete="tel"
            value={phoneNumber}
            onChangeText={(newText) => {
              setIsSubmitSuccess(false);
              setPhoneNumber(newText);
              setErrorMessages({
                ...errorMessages,
                phoneNumber: undefined,
              });
            }}
            placeholder="Enter your phone number..."
            errorMessage={errorMessages.phoneNumber}
          />
          <TextInput
            className={styles.input}
            testId="estimated-guest-count-input"
            label="Estimated Number of People*"
            value={estimatedGuestCount}
            onChangeText={(newText) => {
              setIsSubmitSuccess(false);
              setEstimatedGuestCount(newText);
              setErrorMessages({
                ...errorMessages,
                estimatedGuestCount: undefined,
              });
            }}
            placeholder="250..."
            errorMessage={errorMessages.estimatedGuestCount}
          />
          <TextInput
            className={styles.input}
            testId="estimated-budget-input"
            label="Estimated Budget*"
            value={estimatedBudget}
            onChangeText={(newText) => {
              setIsSubmitSuccess(false);
              setEstimatedBudget(newText);
              setErrorMessages({
                ...errorMessages,
                estimatedBudget: undefined,
              });
            }}
            placeholder="1000..."
            errorMessage={errorMessages.estimatedBudget}
          />
          <TextInput
            className={styles.input}
            testId="event-date-input"
            label="Event Date*"
            value={eventDate}
            onChangeText={(newText) => {
              setIsSubmitSuccess(false);
              setEventDate(newText);
              setErrorMessages({
                ...errorMessages,
                eventDate: undefined,
              });
            }}
            placeholder="Enter the event date..."
            errorMessage={errorMessages.eventDate}
            type="date"
            min={new Date().toISOString().split("T")[0]}
          />
          <div className={styles.addressAutocomplete}>
            <TextInput
              className={styles.input}
              testId="event-location-input"
              label="Event Location*"
              value={eventLocationSearch}
              onChangeText={(newText) => {
                setIsSubmitSuccess(false);
                setSelectedEventLocation("");
                setEventLocationSearch(newText);
                setErrorMessages({
                  ...errorMessages,
                  eventLocation: undefined,
                });
                getPlacePredictions({
                  input: newText,
                });
              }}
              placeholder="Enter the event location..."
              errorMessage={errorMessages.eventLocation}
              inputClassName={classNames({
                [styles.noBorderRadius]:
                  showAddressSearchResults || isPlacePredictionsLoading,
              })}
            />
            {isPlacePredictionsLoading && (
              <div className={styles.addressLoading}>
                <ReactLoading
                  color={design.buttonColor}
                  type="spin"
                  height={25}
                  width={25}
                />
              </div>
            )}
            {showAddressSearchResults && (
              <div className={styles.addressPredictions}>
                {placePredictions.map((prediction, index) => (
                  <button
                    key={index}
                    className={styles.addressPredictionButton}
                    onClick={() => {
                      setSelectedEventLocation(prediction.description);
                      setEventLocationSearch(prediction.description);
                    }}
                    data-testid="address-autocomplete-option"
                  >
                    <p className={styles.addressPredictionText}>
                      {prediction.description}
                    </p>
                  </button>
                ))}
              </div>
            )}
          </div>
          <TextInput
            className={styles.input}
            testId="company-name-input"
            label="Company Name"
            value={companyName}
            onChangeText={(newText) => {
              setIsSubmitSuccess(false);
              setCompanyName(newText);
            }}
            placeholder="Enter your company name..."
          />
          <TextInput
            className={styles.input}
            testId="notes-input"
            label="Notes"
            value={notes}
            onChangeText={(newText) => {
              setIsSubmitSuccess(false);
              setNotes(newText);
            }}
            placeholder="Enter any notes you have about your event..."
          />
          {isSubmitSuccess && (
            <div className={styles.successBox}>
              <p
                className={styles.successBoxText}
                data-testid="success-box-text"
              >
                {getEnvVariable("IS_ADVANCED_CATERING_ENABLED") === "true"
                  ? "Catering request created successfully. Please check your email to communicate with us."
                  : "Catering request submitted successfully! We will get back to you within a week."}
              </p>
            </div>
          )}
          {isLoading ? (
            <div className={styles.loadingSpinnerContainer}>
              <ReactLoading
                type="spin"
                color={design.buttonColor}
                height={30}
                width={30}
              />
            </div>
          ) : (
            <Button
              testId="submit-catering-form-button"
              className={styles.submitInfoButton}
              onClick={() => {
                if (areFieldsValid()) {
                  if (
                    getEnvVariable("IS_ADVANCED_CATERING_ENABLED") === "true"
                  ) {
                    handleCreatingCateringRequest();
                  } else {
                    handleEmailCateringFormToRestaurant();
                  }
                }
              }}
            >
              <h3 className={styles.buttonText}>Submit Catering Request</h3>
            </Button>
          )}
        </div>
      )}
      {categoriesWithCateringItems.length > 0 && (
        <h1 className={styles.sectionHeader}>Catering Menu</h1>
      )}
      <div className={styles.categoriesContainer}>
        {categoriesWithCateringItems.map((category) => (
          <div
            key={category.id}
            className={styles.categoryContainer}
            data-testid={`category-container-${category.id}`}
          >
            <h2 className={styles.sectionHeader}>{category.name}</h2>
            <div className={styles.itemsGrid}>
              {Object.values(items[category.id])
                .filter((item) => item.isCateringItem)
                .map((item) => (
                  <Card
                    testId={`item-card-${item.id}`}
                    link={getItemPath(category.id, item.id)}
                    key={item.id}
                    image={item.hasImage ? item.imageURL : undefined}
                    title={item.name}
                    price={item.price > 0 ? item.price : undefined}
                    description={item.description}
                    showAddIndicator={false}
                  />
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
