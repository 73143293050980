// Catering form submission functions
import { post } from "src/api/server";

export const createCateringRequestInDatabase = async (
  restaurantId: string,
  customerName: string,
  companyName: string,
  emailAddress: string,
  phoneNumber: string,
  estimatedNumberOfGuests: number,
  estimatedBudget: number,
  eventDate: string,
  eventLocation: string,
  notes: string,
): Promise<void> => {
  await post({
    endpoint: "/catering/create_catering_request",
    params: {
      restaurantId,
      customerName,
      companyName,
      emailAddress,
      phoneNumber,
      estimatedNumberOfGuests,
      estimatedBudget,
      eventDate,
      eventLocation,
      notes,
    },
  });
};

/**
 * @deprecated
 */
export const createCateringFormSubmission = async (
  restaurantId: string,
  name: string,
  email: string,
  phone: string,
  estimatedGuestCount?: string,
  estimatedBudget?: string,
  eventDate?: string,
  eventLocation?: string,
  notes?: string,
): Promise<void> => {
  await post({
    endpoint: "/catering/submit_form",
    params: {
      restaurantId,
      name,
      email,
      phone,
      estimatedGuestCount,
      estimatedBudget,
      eventDate,
      eventLocation,
      notes,
    },
  });
};
