import { getCurrentEnvironment } from "src/config/getConfig";

const constructPath = (path: string) => {
  if (getCurrentEnvironment() === "test") {
    return path;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const bundleId = urlParams.get("bundleId");
  const promoId = urlParams.get("promoId");
  const promoSource = urlParams.get("promoSource");

  const params: string[] = [];
  if (bundleId) {
    params.push(`bundleId=${bundleId}`);
  }
  if (promoId) {
    params.push(`promoId=${promoId}`);
  }
  if (promoSource) {
    params.push(`promoSource=${promoSource}`);
  }

  if (params.length > 0) {
    const paramString = params.join("&");
    if (path.includes("?")) {
      return `${path}&${paramString}`;
    }
    return `${path}?${paramString}`;
  }

  return path;
};

export const getGenericMenuPath = () => {
  return "/";
};

export const getMenuPath = () => {
  return constructPath("/");
};

export const getGenericCateringPath = () => {
  return "/catering";
};

export const getCateringPath = () => {
  return constructPath("/catering");
};

export const getGenericDealPath = () => {
  return "/deal/:dealId";
};

export const getDealPath = (dealId: string) => {
  return constructPath(`/deal/${dealId}`);
};

export const getGenericCategoryPath = () => {
  return "/category/:categoryId";
};

export const getCategoryPath = (categoryId: string) => {
  return constructPath(`/category/${categoryId}`);
};

export const getGenericItemPath = () => {
  return "/category/:categoryId/item/:itemId";
};

export const getItemPath = (categoryId: string, itemId: string) => {
  return constructPath(`/category/${categoryId}/item/${itemId}`);
};

export const getGenericReceiptPath = () => {
  return "/receipt/:orderId";
};

export const getReceiptPath = (orderId: string) => {
  return constructPath(`/receipt/${orderId}`);
};

export const getGenericCateringInvoicePath = () => {
  return "/catering-invoice/:cateringInvoiceId";
};

export const getCateringInvoicePath = (cateringInvoiceId: string) => {
  return constructPath(`/catering-invoice/${cateringInvoiceId}`);
};

export const getGenericSignInPath = () => {
  return "/sign-in";
};

export const getSignInPath = (redirectPath?: string) => {
  const baseUrl = "/sign-in";

  if (redirectPath) {
    return constructPath(`${baseUrl}?redirect=${redirectPath}`);
  }

  return constructPath(baseUrl);
};

export const getGenericSignUpPath = () => {
  return "/sign-up";
};

export const getSignUpPath = (redirectPath?: string) => {
  const baseUrl = "/sign-up";

  if (redirectPath) {
    return constructPath(`${baseUrl}?redirect=${redirectPath}`);
  }

  return constructPath(baseUrl);
};

export const getGenericForgotPasswordPath = () => {
  return "/forgot-password";
};

export const getForgotPasswordPath = (redirectPath?: string) => {
  const baseUrl = "/forgot-password";

  if (redirectPath) {
    return constructPath(`${baseUrl}?redirect=${redirectPath}`);
  }

  return constructPath(baseUrl);
};

export const getGenericResetPasswordPath = () => {
  return "/reset-password";
};

export const getResetPasswordPath = (email: string, redirectPath?: string) => {
  const baseUrl = `/reset-password?email=${email}`;

  if (redirectPath) {
    return constructPath(`${baseUrl}&redirect=${redirectPath}`);
  }

  return constructPath(baseUrl);
};

export const getGenericTermsPath = () => {
  return "/terms";
};

export const getTermsPath = () => {
  return constructPath("/terms");
};

export const getGenericAccountPath = () => {
  return "/account";
};

export const getAccountPath = () => {
  return constructPath("/account");
};

export const getGenericPersonalInfoPath = () => {
  return "/personal-info";
};

export const getPersonalInfoPath = () => {
  return constructPath("/personal-info");
};

export const getGenericCartPath = () => {
  return "/cart";
};

export const getCartPath = () => {
  return constructPath("/cart");
};

export const getGenericCheckoutPath = () => {
  return "/checkout";
};

export const getCheckoutPath = () => {
  return constructPath("/checkout");
};

export const getGenericWildcardRoute = () => {
  return "*";
};

export const getWildcardRoute = () => {
  return constructPath("*");
};
